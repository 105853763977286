import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { uploadFolder } from '../../utils/api';
import Utils from '../../utils/utils';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const ModalAthleteModelCardUbaClube = ({ show, handleClose, data = {} }) => {
  const { formatCNPJCPF, formatTelefone } = Utils();
  const { t } = useTranslation();
  const componentRef = useRef();

  const uploadURL = uploadFolder();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
    pageStyle: `
      @media print {
        @page { margin: 0; }
      }
      body {
        -webkit-print-color-adjust: exact;
      }
    `,
  });

  return (
    <div className='modal' id='carteirinha' style={show ? { display: 'flex', opacity: 1 } : null}>
      <div className='modal_content' style={{ width: 600 }}>
        <div className='modal_close' onClick={handleClose}>
          X
        </div>
        <h3 className='modal_title'>{t('ModelCard')}</h3>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div ref={componentRef} style={{ width: 550 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                width: '100%',
                borderRadius: 8,
                marginTop: 20,
                padding: '10px 10px',
                background:
                  "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('img/clubes/ubaclube/background.png')",
                backgroundSize: 'cover',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    width: 300,
                    height: 150,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img style={{ borderRadius: 7, height: '100%' }} src='img/clubes/ubaclube/mascote.png' />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 0 }}>
                  <span style={{ fontSize: 25, textAlign: 'center', color: '#fff' }}>ESCOLA DE FUTEBOL</span>
                  <span
                    style={{
                      color: '#fff',
                      fontSize: 40,
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 40,
                    }}
                  >
                    UBA CLUBE
                  </span>
                  <span
                    style={{
                      color: '#fff',
                      display: 'flex',
                      fontSize: 15,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <hr style={{ borderBottom: '1px solid #fff', width: 40, marginRight: 5 }}></hr>
                    MANHUAÇU-MG
                    <hr style={{ borderBottom: '1px solid #fff', width: 40, marginLeft: 5 }}></hr>
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: 300, marginTop: 0 }}>
                  <img src='img/clubes/ubaclube/logo.png' />
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'white',
                    height: 185,
                    width: '30%',
                    borderRadius: 10,
                    border: '1px solid #aaa',
                  }}
                >
                  <img style={{ borderRadius: 7, height:185 }} src={`${uploadURL}/upload/schools/students/${data.rawFoto}`} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '70%',
                    padding: '0 0 0 10px',
                    justifyContent: 'space-around',
                  }}
                >
                  <div
                    style={{
                      background: '#f7d2d1',
                      width: '100%',
                      height: 40,
                      borderRadius: 10,
                      padding: '0 5px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ fontSize: 10, fontWeight: 500 }}>Nome:</span>
                    <span style={{ fontSize: 10 }}>{data.name}</span>
                  </div>
                  <div
                    style={{
                      background: '#f7d2d1',
                      width: '80%',
                      height: 40,
                      borderRadius: 10,
                      padding: '0 5px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ fontSize: 10, fontWeight: 500 }}>Data Nascimento:</span>
                    <span style={{ fontSize: 10 }}>{String(data.usuBirth).split('-').reverse().join('/')}</span>
                  </div>
                  <div
                    style={{
                      background: '#f7d2d1',
                      width: '100%',
                      height: 40,
                      borderRadius: 10,
                      padding: '0 5px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ fontSize: 10, fontWeight: 500 }}>Responsável:</span>
                    <span style={{ fontSize: 10 }}>{data.athNomeRespFin}</span>
                  </div>
                  <div
                    style={{
                      background: '#f7d2d1',
                      width: '80%',
                      height: 40,
                      borderRadius: 10,
                      padding: '0 5px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <span style={{ fontSize: 10, fontWeight: 500 }}>Contato Responsável:</span>
                    <span style={{ fontSize: 10 }}>{formatTelefone(String(data.athPhoneRespFin))}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '160px 0 40px 0',
                marginTop: 20,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                borderRadius: 8,
                background:
                  "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('img/clubes/ubaclube/background.png')",
                backgroundSize: 'cover',
              }}
            >
              <p style={{ fontSize: 20, color: '#fff' }}>
                <b>{data.schName}</b>
              </p>
              <p style={{ fontSize: 15, color: '#fff' }}>{formatCNPJCPF(String(data.schCnpj))}</p>
              <p style={{ fontSize: 15, color: '#fff', marginTop: 15 }}>Contatos:</p>
              <p style={{ fontSize: 15, color: '#fff' }}>ubaclube@gmail.com</p>
              <p style={{ fontSize: 15, color: '#fff' }}>(33) 3331-4162</p>
              <p style={{ fontSize: 15, color: '#fff' }}>(33) 9.8423-7482</p>
              <p style={{ fontSize: 15, color: '#fff' }}>(33) 9.8423-8432</p>
              <p style={{ fontSize: 15, color: '#fff', marginTop: 15 }}>
                Um clube, <b>Uma Familia!</b>
              </p>
            </div>
          </div>
          <button className='btn_card' style={{ marginTop: 20, width: '50%' }} onClick={handlePrint}>
            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
            </svg>
            {t('PDFModelCard')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAthleteModelCardUbaClube;
