import React, { useEffect, useRef, useState } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Utils, { AllMonths, yearsRange } from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { getSchoolBirthdays } from '../../services/SchoolService';
import { useTranslation } from 'react-i18next';
import moment, { months } from 'moment';
import { calcValueToReceive } from '../../utils/utils-financial';
import CardIndicator from '../../components/cards/CardIndicator';
import Logo from '../../components/school/Logo';
import { sendWhatsAppMessage } from '../../services/WhatsAppService';
import { cellPhoneFormatI18n, cpfCnpjFormatI18n, dateFormatI18n } from '../../utils/formatters';
import ModalSimpleTable from '../../components/modal/ModalSimpleTable';
import WhatsAppComponent from '../../components/whatsapp/WhatsAppComponent';
import { Box, Select } from '@material-ui/core';
import { Table } from 'react-bootstrap';
import { TableDashBoard } from '../../components/school/TableDashboard';
import { useReactToPrint } from 'react-to-print';

const Reports = () => {
  const sliderRef = useRef(null);
  const whatsAppComponentRef = useRef();
  const { t, i18n } = useTranslation();
  const school = JSON.parse(localStorage.getItem('school'));
  const tableRef = useRef(null);

  const [alunosCadastrados, setAlunosCadastrados] = useState([]);
  const [alunosEmDia, setAlunosEmDia] = useState([]);
  const [alunosInadimplentes, setAlunosInadimplentes] = useState([]);
  const [alunosInativos, setAlunosInativos] = useState([]);
  const [alunosAtivoSemContrato, setAlunosAtivoSemContrato] = useState([]);

  const [contratosVencido, setContratosVencido] = useState([]);
  const [contratosAtivos, setContratosAtivos] = useState([]);
  const [contratosNovos, setContratosNovos] = useState([]);
  const [contratosVencendo, setContratosVencendo] = useState([]);

  const [fatAReceber, setFatAReceber] = useState([]);
  const [fatRecebido, setFatRecebido] = useState([]);
  const [fatTotal, setFatTotal] = useState([]);
  const [fatVencidos, setFatVencidos] = useState([]);

  const [studentMissingMandatoryDocuments, setStudentMissingMandatoryDocuments] = useState([]);

  // loading
  const [process, setProcess] = useState(false);
  const [processDash, setProcessDash] = useState(false);
  const [isLoadingBirthDays, setIsLoadingBirthDays] = useState(false);
  const [modalAlunos, setModalAlunos] = useState(false);
  const [modalAlunosSemContrato, setModalAlunosSemContrato] = useState(false);
  const [modalContratos, setModalContratos] = useState(false);
  const [modalFaturamento, setModalFaturamento] = useState(false);
  const [modalExameMedico, setModalExameMedico] = useState(false);
  const [modalMissingMandatoryDocuments, setModalMissingMandatoryDocuments] = useState(false);
  const [modalStudentsWithoutContract, setModalStudentsWithoutContract] = useState(false);

  const [dataModalAlunos, setDataModalAlunos] = useState([]);
  const [dataModalContratos, setDataModalContratos] = useState([]);
  const [dataModalFaturamento, setDataModalFaturamento] = useState([]);
  const [dataModalExameMedico, setDataModalExameMedico] = useState([]);
  const [dataModalMissingMandatoryDocuments, setDataModalMissingMandatoryDocuments] = useState([]);
  const [dataModalStudentsWithoutContract, setDataModalStudentsWithoutContract] = useState([]);
  const [titleModal, setTitleModal] = useState('');
  const [subtitleModal, setSubTitleModal] = useState('');

  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [dirUsuFotoPrincipalStudent, setDirUsuFotoPrincipalStudent] = useState('');
  const [users, setUsers] = useState([]);
  const [year, setYear] = useState(Number(moment().format('Y')));
  const [matriculas, setMatriculas] = useState([]);
  const [desistentes, setDesistentes] = useState([]);
  const [inadimplentes, setInadimplentes] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [countStudents, setCountStudents] = useState([]);

  //WhatsApp
  const [detail, setDetail] = useState(null);
  const [message, setMessage] = useState();

  // libraries
  const baseURL = Api();
  const { formatCycloContract } = Utils();

  useEffect(() => {
    async function getSession() {
      const schoolSession = JSON.parse(await localStorage.getItem('school'));
      getReports(schoolSession.id);
      fetchSchoolBirthdays(schoolSession.id);
      getDashBoard(schoolSession.id, year);
    }

    getSession();
  }, []);

  useEffect(() => {
    getDashBoard(school.id, year);
  }, [year]);

  const getReports = async (school) => {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-reports-from-school', {
      school_id: school,
    });

    setAlunosCadastrados(res.data.alunos_cadastrados);
    setAlunosEmDia(res.data.alunos_em_dia);
    setAlunosInadimplentes(res.data.alunos_inadimplentes);
    setAlunosInativos(res.data.alunos_inativos);
    setAlunosAtivoSemContrato(res.data.alunos_sem_contrato);

    setContratosVencido(res.data.contratos_vencido);
    setContratosAtivos(res.data.contratos_ativos);
    setContratosNovos(res.data.contratos_novos);
    setContratosVencendo(res.data.contratos_vencendo);

    setFatAReceber(res.data.faturamento_a_receber.map(calcValueToReceive));
    setFatRecebido(res.data.faturamento_recebido.map(calcValueToReceive));
    setFatTotal(res.data.faturamento_total.map(calcValueToReceive));
    setFatVencidos(res.data.faturamento_vencidos.map(calcValueToReceive));

    setStudentMissingMandatoryDocuments(res.data.estudantes_doc_ausente);

    setProcess(false);
  };

  const getDashBoard = async (school, year) => {
    setProcessDash(true);
    const res = await axios.get(baseURL + `get-details-dashboard/${school}/${year}`);

    const matriculas = AllMonths.map((month) => {
      const item = res.data.matricula.find((item) => item.monthMatricula === month.number);
      return item ? item : { date: '', Total: '' };
    });
    const desistentes = AllMonths.map((month) => {
      const item = res.data.desistentes.find((item) => item.monthClosed === month.number);
      return item ? item : { date: '', Total: '' };
    });
    const inadimplentes = AllMonths.map((month) => {
      const item = res.data.inadimplentes.find((item) => item.dueDate === month.number);
      return item ? item : { date: '', Total: '' };
    });
    const pagos = AllMonths.map((month) => {
      const item = res.data.pagos.find((item) => item.datePaymente === month.number);
      return item ? item : { date: '', Total: '' };
    });
    const students = AllMonths.map((month) => {
      const item = res.data.students.find((item) => item.monthCreate === month.number);
      return item ? item : { date: '', Total: '' };
    });

    setMatriculas(matriculas);
    setDesistentes(desistentes);
    setInadimplentes(inadimplentes);
    setPagos(pagos);
    setCountStudents(students);

    setProcessDash(false);
  };

  const fetchSchoolBirthdays = async (school) => {
    setIsLoadingBirthDays(true);
    const { success, error, usuarios, dir_usuFotoPrincipal, dir_usuFotoPrincipalStudent } = await getSchoolBirthdays(
      school
    );
    setIsLoadingBirthDays(false);

    if (success) {
      setUsers(usuarios.map(({ athlete, ...rest }) => ({ ...athlete, ...rest })));
      setDirUsuFotoPrincipal(dir_usuFotoPrincipal);
      setDirUsuFotoPrincipalStudent(dir_usuFotoPrincipalStudent);
    } else {
      if (error) console.log(error);
    }
  };

  const openModalReports = (type, report) => {
    switch (type) {
      case 'alunos':
        {
          setModalAlunos(true);

          if (report === 1) {
            setTitleModal(
              `${t('SchoolHomePage.AlunoEmDia')} - ${alunosEmDia
                .filter((aluno) => aluno.usuStatus === 'A')
                .map(({ id }) => id)
                .filter((v, i, a) => a.indexOf(v) === i).length
              }`
            );
            setDataModalAlunos(alunosEmDia.filter((aluno) => aluno.usuStatus === 'A'));
            setSubTitleModal(`${t('SchoolHomePage.RegisteredStudents')} - ${alunosCadastrados.length}`);
          } else if (report === 2) {
            const unicosInadimplentes = alunosInadimplentes
              .map(({ id }) => id)
              .filter((v, i, a) => a.indexOf(v) === i).length;
            setSubTitleModal(`FATURAS INADIMPLENTES - ${alunosInadimplentes.length}`);
            setTitleModal(`${t('SchoolHomePage.OverdueStudents')} - ${unicosInadimplentes}`);
            setDataModalAlunos(alunosInadimplentes);
          } else if (report === 3) {
            setSubTitleModal('');
            setTitleModal(`${t('SchoolHomePage.RegisteredStudents')} - ${alunosCadastrados.length}`);
            setDataModalAlunos(alunosCadastrados);
          } else if (report === 4) {
            setSubTitleModal('');
            setTitleModal(`${t('SchoolHomePage.InactiveStudents')} - ${alunosInativos.length}`);
            setDataModalAlunos(alunosInativos);
          }
        }
        break;
      case 'contratos':
        {
          setModalContratos(true);

          if (report === 1) {
            setTitleModal(`${t('SchoolHomePage.ContractsExpiring')} - ${contratosVencendo.length}`);
            setDataModalContratos(contratosVencendo);
          } else if (report === 2) {
            setTitleModal(`${t('SchoolHomePage.ExpiredContracts')} - ${contratosVencido.length}`);
            setDataModalContratos(contratosVencido);
          } else if (report === 3) {
            setTitleModal(`${t('SchoolHomePage.ActiveContracts')} - ${contratosAtivos.length}`);
            setDataModalContratos(contratosAtivos);
          } else if (report === 4) {
            setTitleModal(`${t('SchoolHomePage.NewContracts')} - ${contratosNovos.length}`);
            setDataModalContratos(contratosNovos);
          }
        }
        break;
      case 'faturamento':
        {
          setModalFaturamento(true);

          if (report === 1) {
            setTitleModal(`${t('SchoolHomePage.BillingsToReceive')} - ${fatAReceber.length}`);
            setDataModalFaturamento(fatAReceber);
          } else if (report === 2) {
            setTitleModal(`${t('SchoolHomePage.OverdueBillings')} - ${fatVencidos.length}`);
            setDataModalFaturamento(fatVencidos);
          } else if (report === 3) {
            setTitleModal(`${t('SchoolHomePage.ReceivedBillings')} - ${fatRecebido.length}`);
            setDataModalFaturamento(fatRecebido);
          } else if (report === 4) {
            setTitleModal(`${t('SchoolHomePage.TotalBilling')} - ${fatTotal.length}`);
            setDataModalFaturamento(fatTotal);
          }
        }
        break;
      case 'exame-medico':
        {
          setModalExameMedico(true);

          if (report === 1) {
            const alunos = alunosCadastrados.filter(
              (aluno) => aluno.expirationDateMedicalExamination < moment().subtract(6, 'months').format('YYYY-MM-DD')
            );

            setTitleModal(`${t('SchoolHomePage.MedicalExamExpired')} - ${alunos.length}`);
            setDataModalExameMedico(alunos);
          } else if (report === 2) {
            const alunos = alunosCadastrados.filter((aluno) => aluno.expirationDateMedicalExamination === null);

            setTitleModal(`${t('SchoolHomePage.NoMedicalExam')} - ${alunos.length}`);
            setDataModalExameMedico(alunos);
          }
        }
        break;
      case 'missing-mandatory-documents':
        {
          setModalMissingMandatoryDocuments(true);
          setTitleModal(`${t('SchoolHomePage.MedicalExamExpired')} - ${studentMissingMandatoryDocuments.length}`);
          setDataModalMissingMandatoryDocuments(
            studentMissingMandatoryDocuments.map(({ missing_documents, ...rest }) => ({
              ...rest,
              missingDocuments: missing_documents.map(({ docDocument }) => docDocument).join(', '),
            }))
          );
        }
        break;
      case 'students-without-contract': {
        setModalStudentsWithoutContract(true);
        setTitleModal(`${t('SchoolHomePage.StudentsActiveWithouContract.Title')} - ${alunosAtivoSemContrato.length}`);
        setDataModalStudentsWithoutContract(alunosAtivoSemContrato);
      }
    }
  };

  const closeModal = (type) => {
    switch (type) {
      case 'alunos':
        {
          setModalAlunos(false);
        }
        break;
      case 'contratos':
        {
          setModalContratos(false);
        }
        break;
      case 'faturamento':
        {
          setModalFaturamento(false);
        }
        break;
      case 'exame-medico':
        {
          setModalExameMedico(false);
        }
        break;
      case 'missing-mandatory-documents':
        {
          setModalMissingMandatoryDocuments(false);
        }
        break;
      case 'students-without-contract':
        {
          setModalStudentsWithoutContract(false);
        }
        break;
    }
  };

  const exportToXLS = (type, dados) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let csvData = [];
    let item = {};

    switch (type) {
      case 'alunos':
        {
          for (let i = 0; i < dados.length; i = i + 1) {
            item = {
              [t('Name')]: dados[i].name,
              [t('Value')]: dados[i].value,
              [t('DueDate')]: dateFormatI18n(dados[i].dueDate, i18n.language),
              [t('FinancialResponsibleName')]: dados[i].athNomeRespFin,
              [t('FinancialResponsibleCellPhone')]: cellPhoneFormatI18n(
                String(dados[i].athPhoneRespFin),
                i18n.language
              ),
            };
            csvData.push(item);
          }
        }
        break;
      case 'contratos':
        {
          for (let i = 0; i < dados.length; i = i + 1) {
            item = {
              [t('Student')]: dados[i].nameStudent,
              [t('Value')]: dados[i].value,
              [t('FirstDueDate')]: dateFormatI18n(dados[i].dateStart, i18n.language),
              [t('ContractEndDate')]: dados[i].dateEnd ? dateFormatI18n(dados[i].dateEnd, i18n.language) : '',
              [t('FinancialResponsibleName')]: dados[i].athNomeRespFin,
              [t('FinancialResponsibleCellPhone')]: cellPhoneFormatI18n(
                String(dados[i].athPhoneRespFin),
                i18n.language
              ),
              [t('BillingCycle')]: formatCycloContract(dados[i].cyclo),
            };
            csvData.push(item);
          }
        }
        break;
      case 'faturamento':
        {
          for (let i = 0; i < dados.length; i = i + 1) {
            item = {
              [t('Student')]: dados[i].student,
              [t('Value')]: dados[i].value,
              [t('DueDate')]: dateFormatI18n(dados[i].dueDate, i18n.language),
              [t('Responsible')]: dados[i].athNomeRespFin || dados[i].client,
              [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(
                String(dados[i].athPhoneRespFin || dados[i].phone),
                i18n.language
              ),
            };
            csvData.push(item);
          }
        }
        break;
      case 'exame-medico':
        {
          for (let i = 0; i < dados.length; i = i + 1) {
            item = {
              [t('StudentName')]: dados[i].name,
              [t('Responsible')]: dados[i].athNomeRespFin,
              [t('ExpirationDateMedicalExamination')]: dateFormatI18n(
                dados[i].expirationDateMedicalExamination,
                i18n.language
              ),
              [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(String(dados[i].athPhoneRespFin), i18n.language),
            };
            csvData.push(item);
          }
        }
        break;

      case 'missing-mandatory-documents':
        {
          for (let i = 0; i < dados.length; i = i + 1) {
            item = {
              [t('Student')]: dados[i].student,
              [t('Responsible')]: dados[i].athNomeRespFin,
              [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(String(dados[i].athPhoneRespFin), i18n.language),
              [t('MissingDocuments')]: dados[i].missingDocuments,
            };
            csvData.push(item);
          }
        }
        break;
      case 'students-without-contract':
        {
          for (let i = 0; i < dados.length; i = i + 1) {
            item = {
              [t('StudentName')]: dados[i].name,
              [t('DocumentTypeModel.CPF')]: dados[i].usuCPF,
              [t('Email')]: dados[i].email,
              [t('Responsible')]: dados[i].athNomeRespFin,
              [t('FinancialResponsibleCPF')]: dados[i].athCPFRespFin,
              [t('FinancialResponsibleEmail')]: dados[i].athEmailRespFin,
              [t('ResponsiblePhoneNumber')]: cellPhoneFormatI18n(String(dados[i].athPhoneRespFin), i18n.language),
            };
            csvData.push(item);
          }
        }
        break;
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, titleModal + fileExtension);
  };

  const scrollSlider = (signal, sliderRef) => {
    const scrollSize = 205 * Math.floor(sliderRef.current.offsetWidth / 205);
    const scrollAux =
      signal === '+' ? sliderRef.current.scrollLeft + scrollSize : sliderRef.current.scrollLeft - scrollSize;
    sliderRef.current.scrollTo({
      left: scrollAux,
      behavior: 'smooth',
    });
  };

  //WhatsApp
  const openSendWhatsApp = async (item) => {
    setDetail(item);
    setMessage('');
    whatsAppComponentRef.current.showModal();
  };

  const sendMessageWhatsApp = async (student, message) => {
    const response = await sendWhatsAppMessage({
      numbersList: [i18n.resolvedLanguage === 'pt-BR' ? `55${student.athCelular}` : student.athCelular],
      message: message,
    });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.SendSuccessfully') };
    } else {
      return {
        success: false,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            <b>{student.name}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
            <br />
          </div>
        ),
      };
    }
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
    pageStyle: `
      .btn_card {
        display: none !important;
      }
      * {
        color: black !important;
      }
      .card {
        border: none !important;
      }
      .pdf-title {
        display: block !important;
      }
    `,
  });

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='dashboard-top-card' style={{ gap: '10px' }}>
                <Box className='card w-100' sx={{ maxWidth: ['1000px', '1000px', '365px'] }}>
                  <div className='img_school'>
                    <Logo />
                  </div>
                  <h2 className='card_title mx-auto'>{school.schName}</h2>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Localization')}: </p>
                      <p className='card_details_text'>{school.schLocalizacao}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('Email')}: </p>
                      <p className='card_details_text'>{school.schEmail}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CellPhone')}: </p>
                      <p className='card_details_text'>{cellPhoneFormatI18n(String(school.schPhone), i18n.language)}</p>
                    </div>
                    <div className='card_details'>
                      <p className='card_details_label'>{t('CNPJ')}: </p>
                      <p className='card_details_text'>{cpfCnpjFormatI18n(String(school.schCnpj), i18n.language)} </p>
                    </div>
                  </div>
                </Box>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('Birthday_other')}</h2>
                    <p className='card_subtitle'>{t('SchoolHomePage.BirthdaySubtitle')}</p>
                  </div>

                  {isLoadingBirthDays && (
                    <p className='loading-card'>
                      <Loading type='1' />
                    </p>
                  )}

                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    {users.length > 0 && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RiArrowLeftSLine
                          onClick={() => scrollSlider('-', sliderRef)}
                          size={30}
                          color='#808080'
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    )}
                    <div className='list_card list-card-slider' ref={sliderRef}>
                      {!isLoadingBirthDays &&
                        users
                          .filter((item) => {
                            const birthday = item.usuBirth.split('-');
                            return (
                              item.usuStatus === 'A' &&
                              new Date(birthday[0], birthday[1] - 1, birthday[2]).getMonth() === new Date().getMonth()
                            );
                          })
                          .sort((a, b) => +a.usuBirth.split('-')[2] - +b.usuBirth.split('-')[2])
                          .map((item, i) => {
                            let birthday = item.usuBirth.split('-');
                            birthday = new Date(birthday[0], birthday[1] - 1, birthday[2]);

                            const isToday =
                              birthday.getMonth() === new Date().getMonth() &&
                              birthday.getDate() === new Date().getDate();

                            return (
                              <div
                                className={`card_list ${isToday ? 'border-color-default' : ''}`}
                                style={{ width: '205px', ...(isToday ? { borderStyle: 'solid' } : {}) }}
                                key={`students_${i}`}
                              >
                                <div className='img_list_user'>
                                  <img
                                    src={
                                      item.usuStudent === 'S'
                                        ? dirUsuFotoPrincipalStudent + '/' + item.usuFotoPrincipal
                                        : dirUsuFotoPrincipal + '/' + item.usuFotoPrincipal
                                    }
                                    alt=''
                                  />
                                </div>
                                <div className='content_card'>
                                  <div className='card_details'>
                                    <p className='card_details_title' title={item.name}>
                                      {item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name}
                                    </p>
                                  </div>
                                  <div className='card_details'>
                                    <p className='card_details_text'>
                                      {item.usuStudent === 'S' ? t('Student_one') : t('Employee_one')}
                                    </p>
                                  </div>
                                  <div className='card_details'>
                                    <p className='card_details_label'>{t('Birth')}: </p>
                                    <p className='card_details_text'>{dateFormatI18n(item.usuBirth, i18n.language)}</p>
                                  </div>
                                </div>
                                {item.usuStudent === 'S' && (
                                  <>
                                    <Link
                                      // style={{pointerEvents: 'none', visibility: 'hidden'}}
                                      to={{
                                        pathname: `/detail-student/${item.id}`,
                                      }}
                                      className='btn_card'
                                    >
                                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                      </svg>
                                      {t('SeeAthlete')}
                                    </Link>
                                    <button
                                      style={{
                                        backgroundColor: 'green',
                                        fontWeight: 500,
                                        marginTop: '7px',
                                        border: '1px solid green',
                                      }}
                                      onClick={() => openSendWhatsApp(item)}
                                      className='btn_card'
                                    >
                                      <svg viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                        <g>
                                          <g>
                                            <path
                                              d='M256.064,0h-0.128C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104l98.4-31.456
                                                                C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z M405.024,361.504
                                                                c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264C189.888,347.2,141.44,270.752,137.664,265.792
                                                                c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304c6.176-6.304,16.384-9.184,26.176-9.184
                                                                c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64c6.176,14.88,21.216,51.616,23.008,55.392
                                                                c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744c-3.776,4.352-7.36,7.68-11.136,12.352
                                                                c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616c28.576,25.44,51.744,33.568,60.032,37.024
                                                                c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496c5.12-7.232,11.584-8.128,18.368-5.568
                                                                c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736C411.2,329.152,411.2,344.032,405.024,361.504z'
                                            />
                                          </g>
                                        </g>
                                      </svg>
                                      {t('WhatsAppComponent.SendMessageButton')}
                                    </button>
                                  </>
                                )}
                              </div>
                            );
                          })}
                    </div>
                    {users.length > 0 && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <RiArrowRightSLine
                          onClick={() => scrollSlider('+', sliderRef)}
                          size={30}
                          color='#808080'
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='card' ref={tableRef}>
                <div className='card_header align-items-center'>
                  <h2 className='card_title'>{t('AnnualReview')}</h2>
                  <select
                    name='acceptYears'
                    defaultValue={year}
                    onChange={(e) => setYear(e.target.value)}
                    className='form-control form_control select'
                  >
                    {yearsRange.map((item, index) => (
                      <option key={item + index}>{item}</option>
                    ))}
                  </select>
                </div>

                {processDash && (
                  <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                    <Loading type='1' />
                  </p>
                )}
                {!processDash && (
                  <>
                    <TableDashBoard
                      matriculas={matriculas}
                      desistentes={desistentes}
                      inadimplentes={inadimplentes}
                      pagos={pagos}
                      countStudents={countStudents}
                    />
                    <div className='flex-row align-items-center mx-auto mt-4'>
                      <button
                        onClick={handlePrint}
                        type='button'
                        className='btn_card'
                        style={{ backgroundColor: 'var(--main-color)', color: 'white' }}
                      >
                        {t('Print')}
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className='sidebar sidebar-multiple dashboard-container'>
                <div className='dashboard-columns'>
                  <div className='card' style={{ minWidth: '277.5px' }}>
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.AlunoEmDia')}
                      subtitle={t('SchoolHomePage.AlunoEmDiaSubtitle')}
                      valueIndicator={
                        alunosCadastrados.length -
                        alunosInadimplentes.map(({ id }) => id).filter((v, i, a) => a.indexOf(v) === i).length
                      }
                      onClick={() => openModalReports('alunos', 1)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.OverdueStudents')}
                      subtitle={t('SchoolHomePage.OverdueStudentsSubtitle')}
                      valueIndicator={
                        alunosInadimplentes.map(({ id }) => id).filter((v, i, a) => a.indexOf(v) === i).length
                      }
                      onClick={() => openModalReports('alunos', 2)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.RegisteredStudents')}
                      subtitle={t('SchoolHomePage.RegisteredStudentsSubtitle')}
                      valueIndicator={alunosCadastrados.length}
                      onClick={() => openModalReports('alunos', 3)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.InactiveStudents')}
                      subtitle={t('SchoolHomePage.InactiveStudentsSubtitle')}
                      valueIndicator={alunosInativos.length}
                      onClick={() => openModalReports('alunos', 4)}
                    />
                  </div>
                  <div className='card' style={{ minWidth: '277.5px' }}>
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.ContractsExpiring')}
                      subtitle={t('SchoolHomePage.ContractsExpiringSubtitle')}
                      valueIndicator={contratosVencendo.length}
                      onClick={() => openModalReports('contratos', 1)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.ExpiredContracts')}
                      subtitle={t('SchoolHomePage.ExpiredContractsSubtitle')}
                      valueIndicator={contratosVencido.length}
                      onClick={() => openModalReports('contratos', 2)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.ActiveContracts')}
                      subtitle={t('SchoolHomePage.ActiveContractsSubtitle')}
                      valueIndicator={contratosAtivos.length}
                      onClick={() => openModalReports('contratos', 3)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.NewContracts')}
                      subtitle={t('SchoolHomePage.NewContractsSubtitle')}
                      valueIndicator={contratosNovos.length}
                      onClick={() => openModalReports('contratos', 4)}
                    />
                  </div>
                </div>
                <div className='dashboard-columns'>
                  <div className='card' style={{ minWidth: '277.5px' }}>
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.BillingsToReceive')}
                      subtitle={t('SchoolHomePage.BillingsToReceiveSubtitle')}
                      valueIndicator={t('FormatFunction.intlCurrencyWithOptions', {
                        value: fatAReceber.reduce((total, item) => total + Number(item.valueCalculated), 0),
                      })}
                      onClick={() => openModalReports('faturamento', 1)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.OverdueBillings')}
                      subtitle={t('SchoolHomePage.OverdueBillingsSubtitle')}
                      valueIndicator={t('FormatFunction.intlCurrencyWithOptions', {
                        value: fatVencidos.reduce((total, item) => total + Number(item.valueCalculated), 0),
                      })}
                      onClick={() => openModalReports('faturamento', 2)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.ReceivedBillings')}
                      subtitle={t('SchoolHomePage.ReceivedBillingsSubtitle')}
                      valueIndicator={t('FormatFunction.intlCurrencyWithOptions', {
                        value: fatRecebido.reduce((total, item) => total + Number(item.valueCalculated), 0),
                      })}
                      onClick={() => openModalReports('faturamento', 3)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.TotalBilling')}
                      subtitle={t('SchoolHomePage.TotalBillingSubtitle')}
                      valueIndicator={t('FormatFunction.intlCurrencyWithOptions', {
                        value: fatTotal.reduce((total, item) => total + Number(item.valueCalculated), 0),
                      })}
                      onClick={() => openModalReports('faturamento', 4)}
                    />
                  </div>
                  <div className='card' style={{ minWidth: '277.5px', height: 'fit-content' }}>
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.StudentsActiveWithouContract.Title')}
                      subtitle={t('SchoolHomePage.StudentsActiveWithouContract.Subtitle')}
                      valueIndicator={alunosAtivoSemContrato.length}
                      onClick={() => openModalReports('students-without-contract')}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.MedicalExamExpired')}
                      subtitle={t('SchoolHomePage.MedicalExamExpiredSubtitle')}
                      valueIndicator={
                        alunosCadastrados.filter(
                          (aluno) =>
                            aluno.expirationDateMedicalExamination < moment().subtract(6, 'months').format('YYYY-MM-DD')
                        ).length
                      }
                      onClick={() => openModalReports('exame-medico', 1)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.NoMedicalExam')}
                      subtitle={t('SchoolHomePage.NoMedicalExamSubtitle')}
                      valueIndicator={
                        alunosCadastrados.filter((aluno) => aluno.expirationDateMedicalExamination === null).length
                      }
                      onClick={() => openModalReports('exame-medico', 2)}
                    />
                    <hr />
                    <CardIndicator
                      isLoading={process}
                      title={t('SchoolHomePage.StudentsMissingMandatoryDocuments')}
                      subtitle={t('SchoolHomePage.StudentsMissingMandatoryDocumentsSubtitle')}
                      valueIndicator={studentMissingMandatoryDocuments.length}
                      onClick={() => openModalReports('missing-mandatory-documents')}
                    />
                  </div>
                </div>
              </div>
            </main>

            <ModalSimpleTable
              isShow={modalAlunos}
              handleCloseModal={() => closeModal('alunos')}
              title={titleModal}
              subtitle={subtitleModal}
              onExport={() => exportToXLS('alunos', dataModalAlunos)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ name }) => name },
                {
                  label: t('InstallmentAmount'),
                  getter: ({ value }) => t('FormatFunction.intlCurrencyWithOptions', { value: value }),
                },
                { label: t('InstallmentDue'), getter: ({ dueDate }) => dateFormatI18n(dueDate, i18n.language) },
                { label: t('Responsible'), minWidth: 130, getter: ({ athNomeRespFin }) => athNomeRespFin },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin }) => cellPhoneFormatI18n(athPhoneRespFin, i18n.language),
                },
                ...(dataModalAlunos.some(item => 'discounted_installment' in item) ? [
                    {
                      label: "Valor da parcela com desconto",
                      getter: ({ discounted_installment }) => t('FormatFunction.intlCurrencyWithOptions', { value: discounted_installment }),
                    },
                    {
                      label: "Turma",
                      getter: ({ class_name }) => class_name || '-',
                    },
                  ] : []),
              ]}
              data={dataModalAlunos}
            />
            <ModalSimpleTable
              isShow={modalAlunosSemContrato}
              handleCloseModal={() => closeModal('alunos-sem-contrato')}
              title={titleModal}
              subtitle={subtitleModal}
              onExport={() => exportToXLS('alunos', dataModalAlunos)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ name }) => name },
                {
                  label: t('InstallmentAmount'),
                  getter: ({ value }) => t('FormatFunction.intlCurrencyWithOptions', { value: value }),
                },
                { label: t('InstallmentDue'), getter: ({ dueDate }) => dateFormatI18n(dueDate, i18n.language) },
                { label: t('Responsible'), minWidth: 130, getter: ({ athNomeRespFin }) => athNomeRespFin },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin }) => cellPhoneFormatI18n(athPhoneRespFin, i18n.language),
                },
              ]}
              data={dataModalAlunos}
            />

            <ModalSimpleTable
              isShow={modalContratos}
              handleCloseModal={() => closeModal('contratos')}
              title={titleModal}
              onExport={() => exportToXLS('contratos', dataModalContratos)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ nameStudent }) => nameStudent },
                {
                  label: t('InstallmentAmount'),
                  getter: ({ value }) => t('FormatFunction.intlCurrencyWithOptions', { value: value }),
                },
                { label: t('ContractStart'), getter: ({ dateStart }) => dateFormatI18n(dateStart, i18n.language) },
                {
                  label: t('ContractEnd'),
                  getter: ({ dateEnd }) => (dateEnd ? dateFormatI18n(dateEnd, i18n.language) : ''),
                },
                { label: t('Responsible'), minWidth: 130, getter: ({ athNomeRespFin }) => athNomeRespFin },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin }) => cellPhoneFormatI18n(athPhoneRespFin, i18n.language),
                },
                { label: t('BillingCycle'), getter: ({ cyclo }) => formatCycloContract(cyclo) },
              ]}
              data={dataModalContratos}
            />

            <ModalSimpleTable
              isShow={modalFaturamento}
              handleCloseModal={() => closeModal('faturamento')}
              title={titleModal}
              onExport={() => exportToXLS('faturamento', dataModalFaturamento)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ student }) => student || '--' },
                {
                  label: t('InstallmentAmount'),
                  getter: ({ valueCalculated }) =>
                    t('FormatFunction.intlCurrencyWithOptions', { value: valueCalculated }),
                },
                { label: t('InstallmentDue'), getter: ({ dueDate }) => dateFormatI18n(dueDate, i18n.language) },
                {
                  label: t('Responsible'),
                  minWidth: 130,
                  getter: ({ athNomeRespFin, client }) => athNomeRespFin || client,
                },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin, phone }) => cellPhoneFormatI18n(athPhoneRespFin || phone, i18n.language),
                },
              ]}
              data={dataModalFaturamento}
            />

            <ModalSimpleTable
              isShow={modalExameMedico}
              handleCloseModal={() => closeModal('exame-medico')}
              title={titleModal}
              onExport={() => exportToXLS('exame-medico', dataModalExameMedico)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ name }) => name },
                { label: t('Responsible'), minWidth: 130, getter: ({ athNomeRespFin }) => athNomeRespFin },
                {
                  label: t('ExpirationDateMedicalExamination'),
                  getter: ({ expirationDateMedicalExamination }) =>
                    dateFormatI18n(expirationDateMedicalExamination, i18n.language),
                },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin }) => cellPhoneFormatI18n(athPhoneRespFin, i18n.language),
                },
              ]}
              data={dataModalExameMedico}
            />

            <ModalSimpleTable
              isShow={modalMissingMandatoryDocuments}
              handleCloseModal={() => closeModal('missing-mandatory-documents')}
              title={titleModal}
              onExport={() => exportToXLS('missing-mandatory-documents', dataModalMissingMandatoryDocuments)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ student }) => student },
                {
                  label: t('Responsible'),
                  minWidth: 130,
                  getter: ({ athNomeRespFin }) => athNomeRespFin,
                },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin }) => cellPhoneFormatI18n(athPhoneRespFin, i18n.language),
                },
                {
                  label: t('MissingDocuments'),
                  getter: ({ missingDocuments }) => missingDocuments,
                },
              ]}
              data={dataModalMissingMandatoryDocuments}
            />

            <ModalSimpleTable
              isShow={modalStudentsWithoutContract}
              handleCloseModal={() => closeModal('students-without-contract')}
              title={titleModal}
              onExport={() => exportToXLS('students-without-contract', dataModalStudentsWithoutContract)}
              columns={[
                { label: t('StudentName'), minWidth: 130, getter: ({ name }) => name },
                { label: t('DocumentTypeModel.CPF'), minWidth: 100, getter: ({ usuCPF }) => usuCPF },
                { label: t('Email'), minWidth: 130, getter: ({ email }) => email },
                { label: t('Responsible'), minWidth: 130, getter: ({ athNomeRespFin }) => athNomeRespFin },
                { label: t('FinancialResponsibleCPF'), minWidth: 130, getter: ({ athCPFRespFin }) => athCPFRespFin },
                {
                  label: t('FinancialResponsibleEmail'),
                  minWidth: 130,
                  getter: ({ athEmailRespFin }) => athEmailRespFin,
                },
                {
                  label: t('ResponsiblePhoneNumber'),
                  getter: ({ athPhoneRespFin }) => cellPhoneFormatI18n(athPhoneRespFin, i18n.language),
                },
              ]}
              data={dataModalStudentsWithoutContract}
            />

            <Footer />
          </div>
        </div>
      </div>

      {/* //Whatsapp */}
      <WhatsAppComponent
        ref={whatsAppComponentRef}
        sendMessageWhatsApp={() => sendMessageWhatsApp(detail, message)}
        onClose={() => {
          setDetail(null);
          setMessage('');
        }}
        sendButtonText={t('WhatsAppComponent.SendSuccessMessage.SendSuccessfully')}
      >
        {detail && (
          <>
            <div className='img_list_user flex-column' style={{ width: 'auto', height: '100px' }}>
              <img src={dirUsuFotoPrincipalStudent + '/' + detail.usuFotoPrincipal} alt='' style={{ width: '80px' }} />
              <h3 className='whatsapp-title-contrato' style={{ marginBottom: '2px', marginTop: 0 }}>
                <b>{detail.name}</b>
              </h3>
            </div>
            <div className='form_group'>
              <label htmlFor='descript' required className='card_details_label'>
                {t('WhatsAppComponent.WriteYourMessage')}
              </label>
              <textarea
                name='descript'
                value={message}
                className='form_control'
                id=''
                cols='30'
                rows='10'
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
          </>
        )}
      </WhatsAppComponent>
    </main>
  );
};

export default Reports;
