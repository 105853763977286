import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, { handleOnChange } from '../../utils/utils';
import SignCreditService from '../../services/SignatureCreditService';

const { createPlan, getPlansAdmin, updatePlan } = SignCreditService;
const { currencyFormatter } = Utils();

function SignCreditConfig() {
  // dados
  const [plan, setPlan] = useState({
    id: undefined,
    name: '',
    description: '',
    isMain: false,
    value: 0,
    totalCredits: 0,
    features: '',
  });

  const [plans, setPlans] = useState([]);

  //interface
  const [display, setDisplay] = useState(false);

  // process
  const [isLoading, setIsLoading] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const { scrollTop, setThemeSchoolDefault } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();

  useEffect(() => {
    setThemeSchoolDefault();
    fetchAllPlans();
  }, []);

  const fetchAllPlans = async () => {
    setIsLoading(true);
    try {
      const plans = await getPlansAdmin();
      setIsLoading(false);
      setPlans(plans);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onChangePlan = handleOnChange(setPlan);

  const openAdd = () => {
    setPlan({
      id: undefined,
      name: '',
      description: '',
      features: '',
      isMain: false,
      totalCredits: 0,
      value: 0,
    });

    clearMessage();
    setDisplay(true);
  };

  const edit = (plan) => {
    setPlan({ ...plan });

    clearMessage();
    setDisplay(true);
  };

  const closeAdd = () => {
    setDisplay(false);
    clearMessage();
    URL.revokeObjectURL(plan.urlPath);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    try {
      setIsLoading(true);
      const planSaved = plan.id === undefined ? await createPlan(plan) : await updatePlan(plan);
      setPlan(planSaved);
      fetchAllPlans();
    } finally {
      setIsLoading(false);
    }

    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  return (
    <main className='content_panel'>
      <div className='sidebar'>
        <div className='card'>
          <div className='card_header'>
            <h2 className='card_title'>Adicionar planos de assinatura digital</h2>
            <p className='card_subtitle'>Clique abaixo para adicionar um novo plano de assinatura digital.</p>
          </div>
          <button onClick={openAdd} className='btn_card mx-auto mt-4 btn_open_modal'>
            <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
            </svg>
            Adicionar Plano
          </button>
        </div>
      </div>
      <div className='content'>
        <div className='card'>
          <div className='card_header'>
            <h2 className='card_title'>planos</h2>
            <p className='card_subtitle'>Veja abaixo a sua lista de planos cadastrados.</p>
            {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
          </div>

          <div className='list_card'>
            {isLoading && (
              <p className='loading-card'>
                <Loading type='1' />
              </p>
            )}
            {!isLoading &&
              plans.map((plan) => (
                <div className='card_list_turma' key={plan.id}>
                  <div className='content_card'>
                    <div className='card_details'>
                      <p className='card_details_label text_uppercase' style={{ flexDirection: 'row' }}>
                        <span style={{ color: '#3b3b3b' }}>{plan.name}</span>
                      </p>
                      <div style={{ display: 'flex' }} id='botoesEditarTurma'>
                        <p
                          className='card_details_label text_uppercase'
                          style={{ margin: 'auto', marginRight: '10px', color: '#3B3B3B' }}
                        >
                          {currencyFormatter(plan.value * 100)}
                        </p>
                        <button
                          onClick={() => edit(plan)}
                          className='btn_card'
                          style={{
                            marginRight: '1.5%',
                            background: 'orange',
                            fontWeight: 500,
                            border: '1px solid orange',
                          }}
                        >
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          alterar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className='modal' style={display ? { display: 'flex', opacity: 1 } : undefined}>
        <div className='modal_content' ref={modalScroll}>
          <div className='modal_close' onClick={closeAdd}>
            X
          </div>
          <h3 className='modal_title'>{plan.id === undefined ? 'Adicionar Plano' : 'Atualizar Plano'}</h3>
          {error && <MessageDangerMarginTop title='Atenção! ' description={message} />}
          {success && <MessageSuccessMarginTop title='Sucesso! ' description={message} />}
          <form onSubmit={handleSubmit} method='post' className='form_modal'>
            <div className='group_50'>
              <div className='group_50'>
                <div className='form_group'>
                  <label htmlFor='name' className='card_details_label'>
                    Nome do Plano
                  </label>
                  <input
                    required
                    type='text'
                    className='form_control'
                    name='name'
                    value={plan.name}
                    onChange={onChangePlan}
                  />
                </div>
              </div>
              <div className='group_50'>
                <div className='flex align-items-center gap-2'>
                  <label htmlFor='isMain' className='card_details_label'>
                    Plano principal
                  </label>
                  <input
                    type='checkbox'
                    className='form_control'
                    name='isMain'
                    checked={plan.isMain}
                    onChange={() => setPlan((prev) => ({ ...prev, isMain: !plan.isMain }))}
                  />
                </div>
              </div>
            </div>

            <div className='group_50'>
              <div className='group_50'>
                <div className='form_group'>
                  <label htmlFor='value' className='card_details_label'>
                    Valor do plano
                  </label>
                  <input
                    required
                    type='number'
                    step='0.01'
                    className='form_control'
                    name='value'
                    value={plan.value}
                    onChange={onChangePlan}
                  />
                </div>
              </div>
              <div className='group_50'>
                <div className='form_group'>
                  <label htmlFor='tel' className='card_details_label'>
                    Total de créditos
                  </label>
                  <input
                    required
                    type='number'
                    step='1'
                    className='form_control'
                    name='totalCredits'
                    value={plan.totalCredits}
                    onChange={onChangePlan}
                  />
                  <div />
                </div>
              </div>
            </div>

            <div className='form_group'>
              <label htmlFor='features' className='card_details_label'>
                Features (Separadas por linha)
              </label>
              <textarea className='form_control' name='features' value={plan.features} onChange={onChangePlan} />
            </div>

            <div className='form_group'>
              <label className='card_details_label'>Descrição</label>
              <textarea className='form_control' name='description' value={plan.description} onChange={onChangePlan} />
            </div>

            {!isLoading && (
              <button className='btn_card mx-auto'>
                <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                </svg>
                {plan.id === undefined ? 'Adicionar Plano' : 'Atualizar Plano'}
              </button>
            )}
            {isLoading && (
              <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                </svg>
                processando...
                <Loading type='1' style={{ marginLeft: '2%' }} />
              </button>
            )}
          </form>
        </div>
      </div>
    </main>
  );
}

export default SignCreditConfig;
