import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Pagination } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Trans, useTranslation } from 'react-i18next';
import { PiSignatureLight } from 'react-icons/pi';
import NumberFormat from 'react-number-format';
import { Link, useHistory } from 'react-router-dom';
import Utils, { getErrorMessageFromResponse, getURLContractPDF } from '../../../src/utils/utils';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import MoneyMask from '../../components/inputs/MoneyMask';
import Footer from '../../components/school/Footer';
import WhatsAppComponent from '../../components/whatsapp/WhatsAppComponent';
import { auth, client, getAsaasConfigSessionSchool, payment } from '../../services/PaymentService';
import { sendWhatsAppMessageWithFileAlt } from '../../services/WhatsAppService';
import Api, { getEnvironment } from '../../utils/api';
import ModalAthleteModelCard from '../../views/ModalAthleteModelCard';
import ModalAthleteModelCardUbaClube from '../../views/ModalAthleteModelCardUbaClube';
import SignatureContractModal from './SignatureContractModal';
const ContractsStudent = () => {
  const [requestSignatureModalData, setRequestSignatureModalData] = useState({});
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const alertRef = React.useRef(null);
  // indexs
  const [id, setID] = useState(null);
  const [school_id, setSchoolID] = useState('');
  const [school, setSchool] = useState({});
  const [user_id, setUserID] = useState('');
  const [configAsaas, setConfigAsaas] = useState({});
  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));

  // inputs modal
  const [modalClass, setModalClass] = useState('');
  const [modalStudent, setModalStudent] = useState('');
  const [modalService, setModalService] = useState('');
  const [modalCycle, setModalCycle] = useState('MONTHLY'); //{ value: 'MONTHLY', label: 'Mensal' }
  const [modalTypePayment, setModalTypePayment] = useState('');
  const [modalValue, setModalValue] = useState(undefined);
  //modalDateStart: Data do primeiro vencimento do contrato
  const [modalDateStart, setModalDateStart] = useState(() => {
    return new Date().toISOString().split('T')[0];
  });
  const [modalContractDateStart, setModalContractDateStart] = useState(() => {
    return new Date().toISOString().split('T')[0];
  });
  const [modalDateEnd, setModalDateEnd] = useState('');
  const [modalObservation, setModalObservation] = useState('');
  const [modalFaturado, setModalFaturado] = useState('');
  const [modalStatus, setModalStatus] = useState('');
  const [modalPaymentID, setModalPaymentID] = useState('');
  const [modalServices, setModalServices] = useState([]);
  const [modalNome, setModalNome] = useState([]);
  const [modalCargaHoraria, setModalCargaHoraria] = useState('');
  const [modalOriginalClass, setModalOriginalClass] = useState('');
  const [modalInsurance, setModalInsurance] = useState('NONE');
  const [modalOriginalValue, setModalOriginalValue] = useState(undefined);
  const [modalDiscountType, setModalDiscountType] = useState('NONE');
  const [modalDiscount, setModalDiscount] = useState(null);
  const [modalLateInterestType, setModalLateInterestType] = useState('NONE');
  const [modalLateInterest, setModalLateInterest] = useState(null);
  const [modalLateFeeType, setModalLateFeeType] = useState('NONE');
  const [modalLateFee, setModalLateFee] = useState(null);

  // inputs modal disabled
  const [disabled, setDisabled] = useState(false);

  // carregamentos
  const [contracts, setContracts] = useState([]);

  const [contractsInativos, setContractsInativos] = useState([]);
  const [contractsAtivos, setContractsAtivos] = useState([]);

  const [modalStudents, setModalStudents] = useState([]);
  const [modalClasss, setModalClasss] = useState([]);

  // filtros
  const [filterClass, setFilterClass] = useState('');
  const [filterStudent, setFilterStudent] = useState('');
  const [filterService, setFilterService] = useState('');
  const [filterStatus, setFilterStatus] = useState('Ativo');
  const [filterBilling, setFilterBilling] = useState('');
  const [filterSigned, setFilterSigned] = useState('');
  const [contractsFilter, setContractsFilter] = useState([]);
  const [display, setDisplay] = useState({});
  const [displayModalMessage, setDisplayModalMessage] = useState({});
  const [displayModalServicoContratado, setDisplayModalServicoContratado] = useState({});

  // process
  const [process, setProcess] = useState(true);
  const [sendSave, setSendSave] = useState(false);

  const [isPaging, setIsPaging] = useState(false);
  const [awaitPagination, setAwaitPagination] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [onlyStudentClass, setOnlyStudentClass] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState('');
  const [warningFaturamento, setWarningFaturamento] = useState(false);
  const [infoFaturamento, setInfoFaturamento] = useState(false);

  const [displayCarteirinha, setDisplayCarteirinha] = useState(false);
  const [studentCarteirinha, setStudentCarteirinha] = useState({});

  //WhatsApp
  const [detail, setDetail] = useState();

  // outros
  const [dirImage, setDirImage] = useState('');

  // libraries
  const baseURL = Api();
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop, MessageWarningMarginTop } = Message();
  const modalScroll = useRef();
  const whatsAppComponentRef = useRef();
  const tabRef = useRef();

  const [pageAtivos, setPageAtivos] = React.useState(1);
  const [pageInativos, setPageInativos] = React.useState(1);
  const [tabAlunos, setTabAlunos] = React.useState(true);
  const [pageSearch, setPageSearch] = React.useState(1);
  const [isSearch, setIsSeach] = React.useState(false);
  const [contractsSearch, setContractsSearch] = React.useState([]);

  const [numberDataPerPaginate, setNumberDataPerPaginate] = useState(25);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [actionContracts, setActionContracts] = useState('');
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [showInfoCancelContract, setShowInfoCancelContract] = useState(false);
  const [countUpdateContractSuccess, setCountUpdateContractSuccess] = useState(0);
  const [errorUpdateContractUsers, setErrorUpdateContractUsers] = useState([]);
  const [confirmActionLoading, setConfirmActionLoading] = useState(false);
  const [readjustType, setReadjustType] = useState('PERCENTAGE');
  const [readjustValue, setReadjustValue] = useState(0);
  const [showReadjust, setShowReadjust] = useState(false);

  const [standPlans, setStandPlans] = useState([]);
  const [standDefault, setStandDefalt] = React.useState('');

  const STATUS_INATIVOS = 'Inativo,Encerrado';
  const enableDebug = getEnvironment() !== 'PROD';
  const companyDataFromLocalStorage = JSON.parse(localStorage.getItem('company'));
  const subdomain = companyDataFromLocalStorage != null ? companyDataFromLocalStorage.subdomain : null;

  //Action bar buttons ---------------------------------------------------------------
  const BarAction = ({ ativos, row }) => {
    return (
      <div className='d-flex justify-content-around align-itens-center'>
        {/* <BtnSign
            onClick={(e) => {
              e.preventDefault();
              newContract();
            }}
          /> */}
        <BtnSignContract
          row={row}
          onClick={(e) => {
            e.preventDefault();
            setRequestSignatureModalData(row);
          }}
        />
        <BtnEdit
          onClick={(e) => {
            e.preventDefault();
            edit(e, row);
          }}
        />

        <BtnZap
          disabled={row.status.toLowerCase() !== 'ativo'}
          onClick={(e) => {
            e.preventDefault();
            if (row.status.toLowerCase() === 'ativo') openSendWhatsApp(row);
          }}
        />
        <BtnPdf
          disabled={row.status.toLowerCase() !== 'ativo'}
          onClick={(e) => {
            e.preventDefault();
            if (row.status.toLowerCase() === 'ativo') {
              window.open(row.autentique_document_link || getURLContractPDF(row, e));
            }
          }}
        />
        <BtnProf
          onClick={(e) => {
            e.preventDefault();
            openCarteirinha(row);
          }}
        />
        <BtnEye
          onClick={(e) => {
            e.preventDefault();
            history.push(`/detail-student/${row.idStudent}`);
          }}
        />
      </div>
    );
  };

  //Table columns ----------------------------------------------------------------------------------------------------------
  const columnContracts = [
    {
      id: 'id',
      label: '',
      hidden: false,
      align: 'center',
      format: (value) => (
        <SelectStudent
          value={value}
          changeSelectedContracts={changeSelectedContracts}
          checked={selectedContracts.includes(value)}
        />
      ),
    },
    {
      id: 'nameStudent',
      label: t('StudentContractsPage.ListCard.List.Student'),
      align: 'left',
    },
    {
      id: 'value',
      label: t('StudentContractsPage.ListCard.List.Value'),
      align: 'left',
      format: (value) => Utils().currencyFormatterText(value),
    },
    {
      id: 'faturado',
      label: t('StudentContractsPage.ListCard.List.Invoiced'),
      align: 'left',
      format: (value) =>
        value === 'S' ? t('StudentContractsPage.ListCard.List.Yes') : t('StudentContractsPage.ListCard.List.No'),
    },
    {
      id: 'status',
      label: t('StudentContractsPage.ListCard.List.Status'),
      align: 'left',
      format: (value) => <StatusRender value={value.toLowerCase()} />,
    },
    /*     {
      id: 'sign',
      label: 'Assinado',
      align: 'left',
      format: (value) => value,
    }, */
    {
      id: 'id',
      align: 'center',
      label: t('StudentContractsPage.ListCard.List.Action'),
      minWidth: 145,
      format: (id, row) => <BarAction row={row} ativos={row.status === 'Ativo'} />,
    },
  ];

  const columnContractsInativos = [
    {
      id: 'id',
      label: '',
      hidden: true,
      align: 'center',
      format: (value) => '',
    },
    {
      id: 'nameStudent',
      label: t('StudentContractsPage.ListCard.List.Student'),
      align: 'left',
    },
    {
      id: 'value',
      label: t('StudentContractsPage.ListCard.List.Value'),
      align: 'left',
      format: (value) => Utils().currencyFormatterText(value),
    },
    {
      id: 'faturado',
      label: t('StudentContractsPage.ListCard.List.Invoiced'),
      align: 'left',
      format: (value) =>
        value === 'S' ? t('StudentContractsPage.ListCard.List.Yes') : t('StudentContractsPage.ListCard.List.No'),
    },
    {
      id: 'status',
      label: t('StudentContractsPage.ListCard.List.Status'),
      align: 'left',
      format: (value) => <StatusRender value={value.toLowerCase()} />,
    },
    /*     {
      id: 'sign',
      label: 'Assinado',
      align: 'left',
      format: (value) => value,
    }, */
    {
      id: 'id',
      align: 'center',
      label: t('StudentContractsPage.ListCard.List.Action'),
      minWidth: 145,
      format: (id, row) => <BarAction row={row} ativos={row.status === 'Ativo'} />,
    },
  ];

  //ativos
  const handleChangePaginateAtivos = (event, pg) => {
    event.preventDefault();
    setPageAtivos(pg);

    requestContracts(school_id, numberDataPerPaginate, pg, pg * numberDataPerPaginate).then((resp) => {
      tabRef.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      setContractsAtivos((current) => ({
        countRows: current.countRows,
        dirImage: current.dirImage,
        contracts: resp.data.contracts,
      }));
    });
  };

  //inativos
  const handleChangePaginateInativos = (event, pg) => {
    event.preventDefault();
    setPageInativos(pg);

    requestContracts(school_id, numberDataPerPaginate, pg, pg * numberDataPerPaginate, STATUS_INATIVOS).then((resp) => {
      tabRef.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      setContractsInativos((current) => ({
        countRows: current.countRows,
        dirImage: current.dirImage,
        contracts: resp.data.contracts,
      }));
    });
  };

  //Pesquisa
  const handleChaangePaginateSearch = (event, pg) => {
    event.preventDefault();
    setPageSearch(pg);

    requestContracts(school_id, numberDataPerPaginate, pg, pg * numberDataPerPaginate, '', search).then((resp) => {
      tabRef.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
      setContractsSearch((current) => ({
        countRows: current.countRows,
        dirImage: current.dirImage,
        contracts: resp.data.contracts,
      }));
    });
  };

  const handlerTab = (e) => {
    setIsSeach(false);
    setTabAlunos((current) => !current);
  };

  const requestContracts = async (ids, limit, page = 1, offset = 0, status = 'Ativo') => {
    return await axios.post(baseURL + 'get-contracts', {
      school_id: ids,
      pagination: { offset, limit },
      page,
      filters: {
        filterClass: '',
        filterStudent: '',
        filterService: '',
        filterStatus: status,
        filterBilling: '',
        filterSigned: '',
      },
    });
  };

  async function getSession() {
    const usuSession = JSON.parse(await localStorage.getItem('user'));
    const schoolSession = JSON.parse(await localStorage.getItem('school'));
    const companySession = JSON.parse(await localStorage.getItem('company'));

    setUserID(usuSession.id);
    setSchoolID(schoolSession.id);
    setSchool(schoolSession);

    // comentado para verificar se melhora ao renderizar
    setProcess(true);
    // getContracts(schoolSession.id);
    getStudentsAndServicesAndClass(schoolSession.id);

    setConfigAsaas(getAsaasConfigSessionSchool(schoolSession, companySession));
    // recupera os contratos iniciais ativos e inativos
    requestContracts(schoolSession.id, numberDataPerPaginate)
      .then((resp) => {
        setContractsAtivos(resp.data);
      })
      .then(() => {
        // * Contratos inativos

        requestContracts(schoolSession.id, numberDataPerPaginate, 1, 0, STATUS_INATIVOS).then((resp) => {
          setContractsInativos(resp.data);
        });
      });

    (async () => {
      const res = await axios.get(`${baseURL}standardized-plans/contracts/${schoolSession.id}`);
      setStandPlans(res.data);

      const default_plan = res.data.find((plan) => plan.default_plan == 'S');

      if (default_plan && default_plan.id)
        handlerSetDefaultPlan(
          default_plan.id,
          res.data.find((plan) => plan.id == default_plan.id)
        );
    })();
  }

  useEffect(() => {
    getSession();

    /*   window.addEventListener('scroll', triggerPagination);
    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      window.removeEventListener('scroll', triggerPagination);
      window.removeEventListener('keydown', closeModalOnEscape);
    }; */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeModal();
      whatsAppComponentRef.current.closeModal();
      closeCarteirinha();
    }
  };

  const getStudentsAndServicesAndClass = async (schoolId) => {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-students-and-class-and-services', {
      school_id: schoolId,
    });
    setModalClasss(res.data.classs);
    setModalStudents(res.data.students);
    setModalServices(res.data.services);
    setProcess(false);
  };
  /* 
  useEffect(() => {
    const infiniteScrolling = async () => {
      setAwaitPagination(true);

      const res = await axios.post(baseURL + 'get-contracts', {
        school_id: school_id,
        pagination: { offset: page * numberDataPerPaginate, limit: numberDataPerPaginate },
        page: page + 1,
        filters: getFilters(),
      });

      const newContracts = [...contracts, ...res.data.contracts];

      setContracts(newContracts);
      setContractsFilter(newContracts);

      setPage(page + 1);
      setAwaitPagination(false);
      setIsPaging(false);
    };

    if (isPaging && contracts.length > 0 && page < totalPages) {
      infiniteScrolling();
    } else {
      setIsPaging(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaging]); */

  useEffect(() => {
    if (success === true) {
      requestContracts(school_id, numberDataPerPaginate)
        .then((resp) => {
          setContractsAtivos(resp.data);
        })
        .then(() => {
          // * Contratos inativos
          requestContracts(school_id, numberDataPerPaginate, 1, 0, STATUS_INATIVOS).then((resp) => {
            setContractsInativos(resp.data);
          });
        });
    }
  }, [success]);

  /*   useEffect(() => {
    requestContracts(school_id, numberDataPerPaginate)
      .then((resp) => {
        setContractsAtivos(resp.data);
      })
      .then(() => {
        // * Contratos inativos

        requestContracts(school_id, numberDataPerPaginate, 1, 0, STATUS_INATIVOS).then((resp) => {
          setContractsInativos(resp.data);
        });
      });
  }, [numberDataPerPaginate]);

  const triggerPagination = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - window.innerHeight * 0.3
    )
      setIsPaging(true);
  }; */

  async function getContracts(school) {
    setProcess(true);
    const res = await axios.post(baseURL + 'get-contracts', {
      school_id: school,
      pagination: { offset: 0, limit: numberDataPerPaginate },
      page: 1,
      filters: getFilters(),
    });

    setDirImage(res.data.dirImage || 'https://labfut.com.br/upload/schools/students/');
    setContracts(res.data.contracts);
    setContractsAtivos(res.data.contracts.filter((contract) => contract.status === 'Ativo'));
    setContractsInativos(res.data.contracts.filter((contract) => contract.status !== 'Ativo') || contractsInativos);
    setContractsFilter(res.data.contracts);
    setContractsSearch(res.data);
    // setModalClasss(res.data.classs);
    // setModalStudents(res.data.students);
    // setModalServices(res.data.services);

    setTotalPages(Math.ceil(res.data.countRows / numberDataPerPaginate));
    setPage(1);
    setPageSearch(1);
    setProcess(false);
  }

  useEffect(() => {
    const cycleToNumber =
      modalCycle === 'YEARLY'
        ? 12
        : modalCycle === 'SEMIANNUALLY'
        ? 6
        : modalCycle === 'QUARTERLY'
        ? 3
        : modalCycle === 'MONTHLY'
        ? 1
        : 0;
    const insuranceValue =
      modalInsurance === 'NONE'
        ? 0
        : modalInsurance === 'PLAN_A'
        ? 1.99
        : modalInsurance === 'PLAN_B'
        ? 2.99
        : modalInsurance === 'PLAN_C'
        ? 3.99
        : 0;
    const valueWithInsurance = cycleToNumber * modalOriginalValue + cycleToNumber * insuranceValue;
    setModalValue(valueWithInsurance);
  }, [modalCycle, modalInsurance, modalOriginalValue]);

  const getFilters = () => {
    return {
      filterClass,
      filterStudent,
      filterService,
      filterStatus,
      filterBilling,
      filterSigned,
    };
  };

  const search = (e) => {
    e.preventDefault();

    setIsSeach(true);
    getContracts(school_id);
  };

  const openCarteirinha = (item) => {
    setStudentCarteirinha({
      name: item.nameStudent,
      usuBirth: item.usuBirth,
      nameClass: item.nameClass,
      foto: dirImage + '/' + item.usuFotoPrincipal,
      rawFoto: item.usuFotoPrincipal,
      idStudent: item.idStudent,
      idContract: item.id,
      schName: school.schName,
      schPhone: school.schPhone,
      schEmail: school.schEmail,
      schCnpj: school.schCnpj,
      athNomeRespFin: item.athNomeRespFin,
      athPhoneRespFin: item.athPhoneRespFin,
    });

    setDisplayCarteirinha(true);
  };

  const closeCarteirinha = () => {
    setStudentCarteirinha({});
    setDisplayCarteirinha(false);
  };

  useEffect(() => {
    const add = urlParams.get('add');
    if (add === 'true') {
      newContract();
    }
  }, [urlParams]);

  const newContract = (show = true) => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);

    setID(null);
    setModalClass('');
    setOnlyStudentClass(false);
    setModalStudent('');
    setModalService('');
    setModalCycle('MONTHLY');
    setModalInsurance('NONE');
    setModalTypePayment('');
    setModalValue('');
    setModalOriginalValue('');

    setModalDiscountType('NONE');
    setModalDiscount(null);
    setModalLateInterestType('NONE');
    setModalLateInterest(null);
    setModalLateFeeType('NONE');
    setModalLateFee(null);

    setModalDateStart('');
    setModalContractDateStart('');
    setModalDateEnd('');
    setModalObservation('');
    setModalFaturado('N');
    setModalStatus('Ativo');
    setModalPaymentID('');
    setModalCargaHoraria('');

    if (show) {
      setDisplay({
        display: 'flex',
        opacity: 1,
      });
    }
  };

  const closeModal = () => {
    setDisplay({});
    setDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);
    setWarning(false);
    setWarningFaturamento(false);

    const blockedDays = [29, 30, 31];
    const day = new Date(modalDateStart).getUTCDate();

    if (modalStatus === 'Inativo' && modalFaturado === 'S') {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.WarningInactiveContractInvoiced');
      return;
    }
    if (modalDateStart < modalContractDateStart) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.FirstDueDateInvalid');
      return;
    }

    if (modalTypePayment === 'MONEY' && blockedDays.includes(day)) {
      setError(true);
      setMessage('Data de vencimento inválida, os dias 29, 30 e 31 não são permitidos.');
      return;
    }
    if (modalTypePayment === 'MONEY' && modalDateEnd < modalContractDateStart) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.EndDateInvalid');
      return;
    }
    /*   if (!validateCPF(modalStudent.athCPFRespFin, i18n.language)) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.ResponsibleCPFInvalid');
      return;
    } */

    setSendSave(true);
    const { success, message } = await submit();
    setSendSave(false);

    if (success) {
      setSuccess(true);
      setMessage(message);
    } else {
      setError(true);
      setMessage(message);
    }

    scrollTop(modalScroll);
  };

  const submit = async () => {
    const data = {
      school_id,
      user_id_student: modalStudent.idUser,
      class_id: modalClass,
      service: modalService,
      cyclo: modalCycle,
      insurance: modalInsurance,
      payment: modalTypePayment,
      discount_type: modalDiscountType === 'NONE' ? null : modalDiscountType,
      discount: modalDiscount || null,
      late_interest_type: modalLateInterestType === 'NONE' ? null : modalLateInterestType,
      late_interest: modalLateInterest || null,
      late_fee_type: modalLateFeeType === 'NONE' ? null : modalLateFeeType,
      late_fee: modalLateFee || null,
      value: modalValue,
      originalValue: modalOriginalValue,
      dateStart: modalDateStart,
      contractDateStart: modalContractDateStart,
      dateEnd: modalDateEnd,
      observation: modalObservation,
      cargaHoraria: modalCargaHoraria,
      descontoPgtoAntecipado: 0,
      pagination: { offset: 0, limit: numberDataPerPaginate },
      page: 1,
      filters: getFilters(),
    };

    const methodAndData = {
      CREATE: {
        body: {
          ...data,
          user_id,
          faturado: 'N',
          status: modalStatus,
          sign: 'S',
        },
        endpoint: baseURL + 'save-contract-school',
      },
      UPDATE: {
        body: {
          ...data,
          id,
          class_changed: modalOriginalClass != modalClass ? true : false,
          status: modalStatus,
        },
        endpoint: baseURL + 'update-contract-school',
      },
    };

    if (id === null) setModalFaturado('N');
    const METHOD = id === null ? 'CREATE' : 'UPDATE';

    try {
      const res = await axios.post(methodAndData[METHOD].endpoint, methodAndData[METHOD].body);

      setContracts(res.data.contracts);
      setContractsFilter(res.data.contracts);
      setPage(1);

      if (res.data.success) {
        setID(res.data.create.id);
        requestContracts(school_id, numberDataPerPaginate)
          .then((resp) => {
            setContractsAtivos(resp.data);
          })
          .then(() => {
            // * Contratos inativos
            requestContracts(school_id, numberDataPerPaginate, 1, 0, STATUS_INATIVOS).then((resp) => {
              setContractsInativos(resp.data);
            });
          });
        return {
          success: true,
          message: 'StudentContractsPage.SystemMessage.' + res.data.messageKey,
          faturado: res.data.create.faturado,
          contract: res.data.create,
        };
      } else {
        return { success: false, message: 'StudentContractsPage.SystemMessage.' + res.data.messageKey };
      }
    } catch (error) {
      console.error(error);
      return { success: false, message: 'SystemMessage.Fail.InternalError' };
    }
  };

  const edit = (e, contract) => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setWarningFaturamento(false);
    setInfoFaturamento(false);

    setID(contract.id);

    setModalOriginalValue(contract.value || contract.originalValue);

    setModalValue(contract.value);

    setModalClass(contract.class_id);
    setOnlyStudentClass(false);
    setModalOriginalClass(contract.class_id);
    setModalStudent({
      idUser: contract.user_id_student,
      name: contract.nameStudent,
      athNomeRespFin: contract.athNomeRespFin,
      athEmailRespFin: contract.athEmailRespFin,
      athPhoneRespFin: contract.athPhoneRespFin,
      athCPFRespFin: contract.athCPFRespFin,
      postalCode: contract.athCep,
      address: contract.athLogradouro,
      addressNumber: contract.athNumero,
      province: contract.athBairro,
    });
    setModalService(contract.service);
    setModalCycle(contract.cyclo);
    setModalInsurance(contract.insurance);
    setModalTypePayment(contract.payment);

    setModalDiscountType(!contract.discount_type ? 'NONE' : contract.discount_type);
    setModalDiscount(contract.discount);
    setModalLateInterestType(!contract.late_interest_type ? 'NONE' : contract.late_interest_type);
    setModalLateInterest(contract.late_interest);
    setModalLateFeeType(!contract.late_fee_type ? 'NONE' : contract.late_fee_type);
    setModalLateFee(contract.late_fee);

    setModalDateStart(contract.dateStart);
    setModalContractDateStart(contract.contractDateStart ? contract.contractDateStart : '');
    setModalDateEnd(contract.dateEnd);
    setModalObservation(contract.observation);
    setModalFaturado(contract.faturado);
    setModalPaymentID(contract.payment_subscription);
    setModalStatus(contract.status);
    setModalCargaHoraria(contract.cargaHoraria);

    if (contract.faturado == 'S') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const showMessage = () => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setWarningFaturamento(true);
    setInfoFaturamento(false);
    scrollTop(modalScroll);
  };

  const showMessageInfo = () => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setWarningFaturamento(false);
    setInfoFaturamento(true);
    scrollTop(modalScroll);
  };

  const handleFaturar = async () => {
    setError(false);
    setSuccess(false);
    setWarning(false);

    if (true /* validaCPF(modalStudent.athCPFRespFin) */) {
      setSendSave(true);
      setWarningFaturamento(false);
      setInfoFaturamento(false);

      const saveResult = await submit();
      if (!saveResult.success) {
        setSendSave(false);
        setError(true);
        setMessage(saveResult.message);
      } else if (saveResult.faturado === 'S') {
        edit(null, saveResult.contract);
        setSendSave(false);
        setError(true);
        setMessage(t('ContractAlreadyInvoiced'));
        getContracts(school_id);
      } else if (
        configAsaas.cnpj &&
        configAsaas.password &&
        configAsaas.token_assas &&
        configAsaas.wallet &&
        configAsaas.utilizaFaturamento === 'S'
      ) {
        const resAuth = await auth(configAsaas.cnpj, configAsaas.password, configAsaas.loginEmail);

        if (resAuth.status == 200) {
          if (resAuth.connection == undefined) {
            if (resAuth.data.token != undefined) {
              // envia o cliente para obter o customer_id
              const resCli = await client(
                modalStudent.athCPFRespFin,
                modalStudent.athNomeRespFin + ' (' + modalStudent.name + ')',
                modalStudent.athEmailRespFin,
                modalStudent.athPhoneRespFin,
                configAsaas.system_id,
                resAuth.data.user_id,
                configAsaas.token_assas,
                '',
                {
                  postalCode: modalStudent.postalCode,
                  address: modalStudent.address,
                  addressNumber: modalStudent.addressNumber,
                  province: modalStudent.province,
                }
              );

              if (resCli.status == 200 && typeof resCli.data.message === 'string') {
                const descriptionCycle = {
                  MONTHLY: 'mensal',
                  QUARTERLY: 'trimestral',
                  SEMIANNUALLY: 'semestral',
                  YEARLY: 'anual',
                };
                const descriptionTypePayment = {
                  CREDIT_CARD: 'cartão de crédito',
                  BOLETO: 'boleto',
                  MONEY: 'dinheiro',
                };

                const data = {
                  billingType: modalTypePayment,
                  customer: resCli.data.data.customer,
                  nextDueDate: modalDateStart,
                  cycle: modalCycle,
                  endDate: '',
                  value: modalValue,
                  client_id: resCli.data.data.id,
                  description: `Contrato com cobrança ${descriptionCycle[modalCycle]} por ${descriptionTypePayment[modalTypePayment]}`,
                  contract: id,
                  token_assas: configAsaas.token_assas,
                  user_id: user_id,
                  user_id_student: modalStudent.idUser,
                };

                if (configAsaas.percentual) {
                  data.split = [
                    {
                      walletId: configAsaas.wallet,
                      percentualValue: configAsaas.percentual,
                    },
                  ];
                } else if (configAsaas.valor) {
                  data.split = [
                    {
                      walletId: configAsaas.wallet,
                      fixedValue: configAsaas.valor,
                    },
                  ];
                }

                if (modalDiscount) {
                  data.discount = { value: modalDiscount, dueDateLimitDays: 0, type: modalDiscountType };
                }

                if (modalLateInterest) {
                  data.interest = { value: modalLateInterest, type: modalLateInterestType };
                }

                if (modalLateFee) {
                  data.fine = { value: modalLateFee, type: modalLateFeeType };
                }

                // envia pagamento
                const endpoint =
                  modalTypePayment == 'BOLETO'
                    ? 'payment-boleto-assinatura'
                    : 'payment-cart-cred-assinatura-invoice-url';
                const resPayment = await payment(endpoint, data);

                if (resPayment.data.data.paymentResponse != undefined) {
                  if (resPayment.data.data.paymentResponse.status == 'ACTIVE') {
                    setDisabled(true);
                    setError(false);
                    setSuccess(true);
                    setWarningFaturamento(false);
                    setMessage('StudentContractsPage.SystemMessage.SubscribeApprovedNotifyStudent');
                    setDisabled(true);
                    setModalFaturado('S');
                    getContracts(school_id);

                    setTimeout(() => {
                      closeModal();
                    }, 3000);
                  } else {
                    setDisabled(false);
                    setError(true);
                    setSuccess(false);
                    setWarning(false);
                    setWarningFaturamento(true);
                    setModalFaturado('N');
                    setMessage('StudentContractsPage.SystemMessage.SubscribeUnapproved');
                  }
                } else if (resPayment.data.data.message != undefined) {
                  setError(true);
                  const msgs = getErrorMessageFromResponse(resPayment.data.data.message);
                  // let msgs = '';
                  // let prop = '';
                  // for (prop in resPayment.data.data.message) {
                  //   if (resPayment.data.data.message.hasOwnProperty(prop)) {
                  //     if (resPayment.data.data.message[prop].message != undefined) msgs += resPayment.data.data.message[prop].message + ', ';
                  //     else msgs += resPayment.data.data.message[prop].description + ' ';
                  //   }
                  // }
                  setMessage(msgs);
                } else if (resPayment.data.data.error != undefined) {
                  setError(true);
                  setMessage('StudentContractsPage.SystemMessage.CommunicationProblemServer');
                  console.log('Nao foi possivel validar o pagamento.');
                  setWarningFaturamento(true);
                }
              } else if (resCli.status == 200 && Array.isArray(resCli.data.message)) {
                setError(true);
                let message = [
                  'Erro ao validar as informações do cliente.',
                  ...resCli.data.message
                    .map(({ field, message }) => {
                      switch (field) {
                        case 'email':
                          return `- Endereço de e-mail inválido: ${modalStudent.athEmailRespFin}`;

                        case 'phone':
                          return `- Telefone inválido: ${modalStudent.athPhoneRespFin}`;

                        case 'address':
                          return `- Endereço maior do que 55 caracteres: ${[
                            modalStudent.postalCode,
                            modalStudent.address,
                            modalStudent.addressNumber,
                            modalStudent.province,
                          ]
                            .filter((f) => f)
                            .join(', ')}`;

                        default:
                          return `\n- ${message}`;
                      }
                    })
                    .filter((f) => f),
                ].join('\n');
                setMessage(message);
                setWarningFaturamento(true);
              } else {
                setError(true);
                setMessage('StudentContractsPage.SystemMessage.CommunicationProblemServer');
                console.log('Nao foi possivel enviar o cliente.', resCli.data.message);
                setWarningFaturamento(true);
              }
            } else {
              setError(true);
              setMessage('StudentContractsPage.SystemMessage.CommunicationProblemServer');
              console.log('Autenticação com a API inválida.');
              setWarningFaturamento(true);
            }
          } else {
            setError(true);
            setMessage('StudentContractsPage.SystemMessage.CommunicationProblemServer');
            console.log('Erro ao comunicar com o servidor.');
            setWarningFaturamento(true);
          }
        } else {
          setError(true);
          setMessage('StudentContractsPage.SystemMessage.CommunicationProblemServer');
          console.log('Erro ao comunicar com o servidor.');
          setWarningFaturamento(true);
        }
      } else {
        setError(true);
        setMessage('StudentContractsPage.SystemMessage.SchoolMustBeParameterized');
        console.log('Erro ao comunicar com o servidor.');
        setWarningFaturamento(false);
        setSendSave(false);
      }

      setSendSave(false);
    } else {
    }
  };

  const handleFaturarDinheiro = async () => {
    setError(false);
    setSuccess(false);
    setWarning(false);

    if (modalStatus === 'Inativo' && modalFaturado === 'S') {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.WarningInactiveContractInvoiced');
      return;
    }
    if (modalDateStart < modalContractDateStart) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.FirstDueDateInvalid');
      return;
    }
    if (modalTypePayment === 'MONEY' && modalDateEnd < modalContractDateStart) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.EndDateInvalid');
      return;
    }
    /*   if (!validateCPF(modalStudent.athCPFRespFin, i18n.language)) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.ResponsibleCPFInvalid');
      return;
    } */

    setSendSave(true);
    setWarningFaturamento(false);
    setInfoFaturamento(false);

    if (
      !configAsaas.cnpj &&
      !configAsaas.password &&
      !configAsaas.token_assas &&
      !configAsaas.wallet &&
      configAsaas.utilizaFaturamento !== 'S'
    ) {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.SchoolMustBeParameterized');
      console.log('Erro ao comunicar com o servidor.');
      setWarningFaturamento(false);
      setSendSave(false);
      return;
    }

    const saveResult = await submit();
    if (!saveResult.success) {
      setSendSave(false);
      setError(true);
      setMessage(saveResult.message);
      return;
    }

    let description = '';
    if (modalCycle === 'MONTHLY') description = 'mensal';
    else if (modalCycle === 'QUARTERLY') description = 'trimestral';
    else if (modalCycle === 'SEMIANNUALLY') description = 'semestral';
    else description = 'anual';

    description = `Contrato com cobrança ${description} em dinheiro`;

    const data = {
      billingType: modalTypePayment,
      nextDueDate: modalDateStart,
      cycle: modalCycle,
      endDate: modalDateEnd,
      startDate: modalContractDateStart,
      value: modalValue,
      description: description,
      contract: id,
      user_id: user_id,
      user_id_student: modalStudent.idUser,
    };

    if (modalDiscount) {
      data.discount = { value: modalDiscount, dueDateLimitDays: 0, type: modalDiscountType };
    }

    if (modalLateInterest) {
      data.interest = { value: modalLateInterest, type: modalLateInterestType };
    }

    if (modalLateFee) {
      data.fine = { value: modalLateFee, type: modalLateFeeType };
    }

    // envia pagamento
    const resPayment = await axios.post(baseURL + 'payment-dinheiro-assinatura', data);

    if (resPayment.data.success === true) {
      setDisabled(true);
      setError(false);
      setSuccess(true);
      setWarningFaturamento(false);
      setMessage('StudentContractsPage.SystemMessage.SubscribeApproved');
      setDisabled(true);
      setModalFaturado('S');
      getContracts(school_id);

      setTimeout(() => {
        closeModal();
      }, 3000);
    } else {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.ValidationError');
      setWarningFaturamento(true);
    }

    setSendSave(false);
    scrollTop(modalScroll);
  };

  const handleCancelarFaturamento = async () => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setInfoFaturamento(false);

    const data = {
      idAsaas: modalPaymentID,
      token_assas: configAsaas.token_assas,
      contract_id: id,
      contract_type: 'SCHOOL',
    };

    // envia pagamento
    setSendSave(true);
    const resp = await payment('payment-cancelar-assinatura', data);
    setSendSave(false);

    if (resp.data.data.deleted || resp.data.data.status === 'WARNING') {
      if (resp.data.data.deleted) {
        setSuccess(true);
        setMessage('StudentContractsPage.SystemMessage.InvoiceCanceled');
      } else {
        setWarning(true);
        setMessage(resp.data.data.messageKey);
      }

      setDisabled(false);
      setModalFaturado('N');
      getContracts(school_id);
    } else {
      setInfoFaturamento(true);
      setError(true);

      console.log(resp);
      if (resp.data.data.message === "TypeError: Cannot read property 'subscription' of null")
        setMessage('StudentContractsPage.SystemMessage.InvoiceCancelFailTemp');
      else setMessage('StudentContractsPage.SystemMessage.InvoiceCancelFail');
    }

    scrollTop(modalScroll);
  };

  const handleCancelarFaturamentoDinheiro = async () => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    setInfoFaturamento(false);

    const data = {
      contract_id: id,
    };

    // envia pagamento
    setSendSave(true);
    const resp = await axios.post(baseURL + 'payment-dinheiro-cancelar-assinatura', data);
    setSendSave(false);

    if (resp.data.success) {
      setSuccess(true);
      setMessage('StudentContractsPage.SystemMessage.InvoiceCanceled');
      setDisabled(false);
      setModalFaturado('N');
      getContracts(school_id);
    } else {
      setError(true);
      setMessage('StudentContractsPage.SystemMessage.InvoiceCancelFail');
      setInfoFaturamento(true);
    }

    scrollTop(modalScroll);
  };

  useEffect(() => {
    if (error || success || warningFaturamento || infoFaturamento || sendSave)
      setDisplayModalMessage({
        display: 'flex',
        opacity: 1,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, success, warningFaturamento, infoFaturamento, sendSave]);

  const newServicoContratado = () => {
    setModalNome('');
    //scrollTop(modalScrollClientFornecedor);

    setDisplayModalServicoContratado({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeModalServicoContratado = () => {
    setDisplayModalServicoContratado({});
  };

  const handleSaveServicoContratado = async (e) => {
    e.preventDefault();

    setError(false);
    setSuccess(false);
    setWarning(false);

    setSendSave(true);
    const res = await axios.post(baseURL + 'save-contract-service', {
      nome: modalNome,
      school_id,
    });
    setSendSave(false);
    setSuccess(true);
    setModalNome('');
    setMessage('StudentContractsPage.SystemMessage.ServiceCreatedSuccess');

    setModalServices([
      ...modalServices,
      {
        id: res.data.id,
        nome: modalNome,
        school_id,
      },
    ]);
  };

  const handleDeleteServicoContratado = async (id) => {
    setError(false);
    setSuccess(false);
    setWarning(false);

    setSendSave(true);
    await axios.post(baseURL + 'delete-contract-service', {
      id,
    });

    setSendSave(false);
    setSuccess(true);
    setModalNome('');
    setMessage('StudentContractsPage.SystemMessage.ServiceDeletedSuccess');

    setModalServices(
      modalServices.filter((item) => {
        return item.id !== id;
      })
    );
  };

  //WhatsApp
  const openSendWhatsApp = async (item) => {
    setDetail(item);
    whatsAppComponentRef.current.showModal();
  };

  const sendContractViaWhatsApp = async (item) => {
    const linkPdf = getURLContractPDF(item, false);
    const message = `*CONTRATO - PDF*\nOlá, ${item.nameStudent}. Segue seu contrato.`;

    const response = await sendWhatsAppMessageWithFileAlt({
      numbersList: [i18n.resolvedLanguage === 'pt-BR' ? `55${item.athPhoneRespFin}` : item.athPhoneRespFin],
      mimeType: 'pdf',
      message: message,
      fileLink: linkPdf,
    });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.ContractSendSuccessfully') };
    } else {
      return {
        success: false,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            <b>{item.nameStudent}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
            <br />
          </div>
        ),
      };
    }
  };

  const changeContractPage = (value) => {
    setNumberDataPerPaginate(value);
  };

  const changeSelectedContracts = (id, checked) => {
    if (checked) {
      if (!selectedContracts.includes(id)) {
        setSelectedContracts((selectedContracts) => [...selectedContracts, id]);
      }
    } else {
      setSelectedContracts(
        selectedContracts.filter(function (idContract) {
          return idContract !== id;
        })
      );
    }
  };

  const handleActionContract = (value) => {
    setShowConfirmAction(true);
    setActionContracts(value);

    if (selectedContracts.length === 0) setActionContracts('');
  };

  const confirmActionContract = async () => {
    if (confirmActionLoading) return;

    setConfirmActionLoading(true);

    const pathname =
      actionContracts === 'ic'
        ? 'inactive-contracts'
        : actionContracts === 'ct'
        ? 'cancel-invoicing-contracts'
        : 'readjust-invoicing-contracts';
    const readjust = actionContracts === 'rc' ? { readjust_type: readjustType, readjust_value: readjustValue } : null;

    try {
      const response = await axios.post(`${baseURL}${pathname}`, {
        selectedContracts,
        isHomolog: enableDebug,
        token_assas: configAsaas.token_assas,
        contract_type: 'SCHOOL',
        ...readjust,
      });

      setConfirmActionLoading(false);
      setActionContracts('');
      setSelectedContracts([]);
      setShowInfoCancelContract(true);
      setShowConfirmAction(false);
      setShowReadjust(false);
      setCountUpdateContractSuccess(response.data.countSuccess);
      setErrorUpdateContractUsers(response.data.errorUsers);

      requestContracts(school_id, numberDataPerPaginate)
        .then((resp) => {
          setContractsAtivos(resp.data);
        })
        .then(() => {
          // * Contratos inativos
          requestContracts(school_id, numberDataPerPaginate, 1, 0, STATUS_INATIVOS).then((resp) => {
            setContractsInativos(resp.data);
          });
        });
    } catch (error) {
      setConfirmActionLoading(false);
      console.error(error);
      setMessage('SystemMessage.Fail.InternalError');
    }
  };

  const handlerSetDefaultPlan = (id, data) => {
    let contract = standPlans.find((plan) => plan.id == id);

    if (data) {
      contract = data;
    }

    setStandDefalt(id);

    if (contract) {
      setModalOriginalValue(contract?.original_value || contract.value);

      setModalValue(contract.value);

      setModalService(contract.id_service);
      setModalCycle(contract.cycle);
      setModalInsurance(contract.insurance);
      setModalTypePayment(contract.payment);

      setModalDiscountType(!contract.discount_type ? 'NONE' : contract.discount_type);
      setModalDiscount(contract.discount);
      setModalLateInterestType(!contract.late_interest_type ? 'NONE' : contract.late_interest_type);
      setModalLateInterest(contract.late_interest);
      setModalLateFeeType(!contract.late_fee_type ? 'NONE' : contract.late_fee_type);
      setModalLateFee(contract.late_fee);

      setModalDateStart(contract.date_start);
      setModalContractDateStart(contract.contract_date_start);
      setModalDateEnd(contract.date_end);
      setModalObservation(contract.observation ? contract.observation : '');
      setModalFaturado('N');
      setModalStatus(contract.status);
      setModalCargaHoraria(contract.hours);
    } else {
      newContract(false);
    }
  };

  const handleSelectAllClick = (event, rows) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n?.id);
      setSelectedContracts(newSelecteds);
      event.target.checked = true;
      return;
    }
    setSelectedContracts([]);
    event.target.checked = false;
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div style={{ paddingRight: '6rem', paddingLeft: '2rem' }}>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card' style={{ flexDirection: 'column' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentContractsPage.ActionCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentContractsPage.ActionCard.Subtitle')}</p>
                  </div>
                  <div className='card-actions'>
                    <div className='card-action-row'>
                      <button onClick={newContract} className='btn_card col-10 mx-auto my-2' data-modal='#noticia'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('StudentContractsPage.ActionCard.Button')}
                      </button>
                    </div>
                    <div className='card-action-row'>
                      <Link to='/contract/create-mass' className='btn_card col-10 mx-auto my-2'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('StudentContractsPage.ActionCard.ButtonMultiple')}
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentContractsPage.FilterCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentContractsPage.FilterCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='news' className='card_details_label'>
                        {t('Classes')}
                      </label>
                      <select
                        required
                        value={filterClass}
                        className='form_control select'
                        onChange={(e) => setFilterClass(e.target.value)}
                      >
                        <option value=''>{t('All', { context: 'female' })}</option>
                        {modalClasss.map((item, i) => {
                          return (
                            <option key={`mc${item.id}`} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='news' className='card_details_label'>
                        {t('Student')}
                      </label>

                      <input
                        type='text'
                        className='form_control'
                        onChange={(e) => setFilterStudent(e.target.value.trim())}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('ContractedService')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterService}
                        onChange={(e) => setFilterService(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        {modalServices.map((item, i) => (
                          <option key={i} value={item.nome}>
                            {item.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('Status')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterStatus}
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='Ativo'>{t('StatusType.Active')}</option>
                        <option value='Inativo'>{t('StatusType.Inactive')}</option>
                        <option value='Encerrado'>{t('StatusType.Closed')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('Billing')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterBilling}
                        onChange={(e) => setFilterBilling(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='S'>{t('Yes')}</option>
                        <option value='N'>{t('Not')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('Signed')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterSigned}
                        onChange={(e) => setFilterSigned(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='S'>{t('Yes')}</option>
                        <option value='N'>{t('Not')}</option>
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='row'>
                  <div className='col'>
                    <div className='card' style={{ flexDirection: 'column' }}>
                      <p>{t('ActiveContracts')}</p>

                      <h3 className='text-center' style={{ padding: 11 }}>
                        {contractsAtivos?.countRows || 0}
                      </h3>

                      <button
                        style={{ borderRadius: 50, height: 38 }}
                        disabled={tabAlunos}
                        className={`btn ${tabAlunos ? 'btn-secondary' : 'btn_card'}`}
                        onClick={(e) => handlerTab(e, tabAlunos)}
                      >
                        {t('View')}
                      </button>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='card' style={{ flexDirection: 'column' }}>
                      <p>{t('InactiveContracts')}</p>
                      <h3 className='text-center' style={{ padding: 11 }}>
                        {contractsInativos?.countRows || 0}
                      </h3>
                      <button
                        style={{ borderRadius: 50, height: 38 }}
                        disabled={!tabAlunos}
                        className={`btn  ${!tabAlunos ? 'btn-secondary' : 'btn_card'}`}
                        onClick={(e) => handlerTab(e, !tabAlunos)}
                      >
                        {t('View')}
                      </button>
                    </div>
                  </div>
                </div>
                {isSearch === false ? (
                  <div className='row'>
                    <div className='col'>
                      {tabAlunos ? (
                        <div className='card'>
                          <div className='card_header w-100 d-flex flex-row justify-content-between' ref={tabRef}>
                            <div>
                              <h2 className='card_title'>{t('StudentContractsPage.ListCard.Title')}</h2>
                              <p className='card_subtitle'>{t('StudentContractsPage.ListCard.Subtitle')} {process && <Loading type={1} />}</p>
                            </div>
                            <div>
                              <select
                                value={actionContracts}
                                className='form_control select'
                                onChange={(e) => handleActionContract(e.target.value)}
                                style={{ border: '1px solid #ccc', borderRadius: '5px' }}
                              >
                                <option value='' disabled>
                                  Ações
                                </option>
                                <option value='ct'>Cancelar Faturamento(s)</option>
                                <option value='ic'>Inativar Contrato(s)</option>
                                <option value='rc'>Reajustar Contrato(s)</option>
                              </select>
                            </div>
                          </div>
                          <TableList
                            rows={contractsAtivos?.contracts || []}
                            columns={columnContracts}
                            refer={tabRef}
                            selectAll={handleSelectAllClick}
                            selecteds={selectedContracts}
                          />
                          <div className='w-100'>
                            {contractsAtivos?.countRows > 0 && (
                              <div className='d-flex justify-content-between align-items-center py-3'>
                                <Pagination
                                  page={pageAtivos}
                                  onChange={handleChangePaginateAtivos}
                                  count={Math.ceil(contractsAtivos.countRows / numberDataPerPaginate)}
                                  variant='outlined'
                                  shape='rounded'
                                  size='small'
                                />
                                <div className='d-flex'>
                                  <span className='d-flex align-items-center' style={{ fontSize: '14px' }}>
                                    Contratos por página:
                                  </span>
                                  <ul className='d-flex'>
                                    <li>
                                      <button
                                        className={`btn btn-sm ${
                                          numberDataPerPaginate == 25 ? 'btn-outline-warning' : 'btn-warning'
                                        }  mx-1`}
                                        disabled={numberDataPerPaginate == 25 ? true : false}
                                        onClick={() => changeContractPage(25)}
                                      >
                                        25
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className={`btn btn-sm ${
                                          numberDataPerPaginate == 50 ? 'btn-outline-warning' : 'btn-warning'
                                        }  mx-1`}
                                        disabled={numberDataPerPaginate == 50 ? true : false}
                                        onClick={() => changeContractPage(50)}
                                      >
                                        50
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className={`btn btn-sm ${
                                          numberDataPerPaginate == 75 ? 'btn-outline-warning' : 'btn-warning'
                                        }  mx-1`}
                                        disabled={numberDataPerPaginate == 75 ? true : false}
                                        onClick={() => changeContractPage(75)}
                                      >
                                        75
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className='card'>
                          <div className='card_header' ref={tabRef}>
                            <h2 className='card_title'>Alunos sem Contratos</h2>
                            <p className='card_subtitle'>Lista de Alunos com contrato inativo</p>
                          </div>
                          <TableList
                            rows={contractsInativos?.contracts || []}
                            columns={columnContractsInativos}
                            refer={tabRef}
                            selectAll={handleSelectAllClick}
                            selecteds={selectedContracts}
                          />
                          <div className='w-100'>
                            {contractsInativos?.countRows > 0 && (
                              <div className='d-flex justify-content-between align-items-center py-3'>
                                <Pagination
                                  page={pageInativos}
                                  onChange={handleChangePaginateInativos}
                                  count={Math.ceil(contractsInativos.countRows / numberDataPerPaginate)}
                                  variant='outlined'
                                  shape='rounded'
                                />
                                <div className='d-flex'>
                                  <span className='d-flex align-items-center' style={{ fontSize: '14px' }}>
                                    Contratos por página:
                                  </span>
                                  <ul className='d-flex'>
                                    <li>
                                      <button
                                        className={`btn btn-sm ${
                                          numberDataPerPaginate == 25 ? 'btn-outline-warning' : 'btn-warning'
                                        }  mx-1`}
                                        disabled={numberDataPerPaginate == 25 ? true : false}
                                        onClick={() => changeContractPage(25)}
                                      >
                                        25
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className={`btn btn-sm ${
                                          numberDataPerPaginate == 50 ? 'btn-outline-warning' : 'btn-warning'
                                        }  mx-1`}
                                        disabled={numberDataPerPaginate == 50 ? true : false}
                                        onClick={() => changeContractPage(50)}
                                      >
                                        50
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        className={`btn btn-sm ${
                                          numberDataPerPaginate == 75 ? 'btn-outline-warning' : 'btn-warning'
                                        }  mx-1`}
                                        disabled={numberDataPerPaginate == 75 ? true : false}
                                        onClick={() => changeContractPage(75)}
                                      >
                                        75
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='card'>
                    <div className='card_header w-100 d-flex flex-row justify-content-between' ref={tabRef}>
                      <div>
                        <h2 className='card_title'>{t('StudentContractsPage.PageTitle')}</h2>
                        <p className='card_subtitle'>{t('StudentContractsPage.PageSubtitle')} {process && <Loading type={1} />}</p>
                      </div>
                      <div>
                        <select
                          value={actionContracts}
                          className='form_control select'
                          onChange={(e) => handleActionContract(e.target.value)}
                          style={{ border: '1px solid #ccc', borderRadius: '5px' }}
                        >
                          <option value='' disabled>
                            Ações
                          </option>
                          <option value='ct'>Cancelar Faturamento(s)</option>
                          <option value='ic'>Inativar Contrato(s)</option>
                          <option value='rc'>Reajustar Contrato(s)</option>
                        </select>
                      </div>
                    </div>
                    <TableList
                      rows={contractsSearch?.contracts || []}
                      columns={columnContracts}
                      tabRef={tabRef}
                      selectAll={handleSelectAllClick}
                      selecteds={selectedContracts}
                    />
                    <div className='w-100'>
                      {contractsSearch?.countRows > 0 && (
                        <div className='d-flex justify-content-between align-items-center py-3'>
                          <Pagination
                            page={pageSearch}
                            onChange={handleChaangePaginateSearch}
                            count={Math.ceil(contractsSearch.countRows / numberDataPerPaginate)}
                            variant='outlined'
                            shape='rounded'
                          />
                          <div className='d-flex'>
                            <span className='d-flex align-items-center' style={{ fontSize: '14px' }}>
                              Contratos por página:
                            </span>
                            <ul className='d-flex'>
                              <li>
                                <button
                                  className={`btn btn-sm ${
                                    numberDataPerPaginate == 25 ? 'btn-outline-warning' : 'btn-warning'
                                  }  mx-1`}
                                  disabled={numberDataPerPaginate == 25 ? true : false}
                                  onClick={() => changeContractPage(25)}
                                >
                                  25
                                </button>
                              </li>
                              <li>
                                <button
                                  className={`btn btn-sm ${
                                    numberDataPerPaginate == 50 ? 'btn-outline-warning' : 'btn-warning'
                                  }  mx-1`}
                                  disabled={numberDataPerPaginate == 50 ? true : false}
                                  onClick={() => changeContractPage(50)}
                                >
                                  50
                                </button>
                              </li>
                              <li>
                                <button
                                  className={`btn btn-sm ${
                                    numberDataPerPaginate == 75 ? 'btn-outline-warning' : 'btn-warning'
                                  }  mx-1`}
                                  disabled={numberDataPerPaginate == 75 ? true : false}
                                  onClick={() => changeContractPage(75)}
                                >
                                  75
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <WhatsAppComponent
                ref={whatsAppComponentRef}
                sendMessageWhatsApp={() => sendContractViaWhatsApp(detail)}
                onClose={() => {
                  setDetail({});
                }}
                sendButtonText={t('SendContract')}
                // extraSend={[{ title: t('SendModelCard'), onSend: sendModelCardViaWhatsApp }]}
              >
                {detail && (
                  <>
                    <div className='img_list_user'>
                      <img src={dirImage + '/' + detail.usuFotoPrincipal} alt='' />
                    </div>
                    <h3 className='whatsapp-title-contrato' style={{ marginBottom: '2px' }}>
                      {t('Contrato')}: <br /> <b>{detail.nameStudent}</b>
                    </h3>
                  </>
                )}
              </WhatsAppComponent>

              {subdomain == 'ubaclube' ? (
                <ModalAthleteModelCardUbaClube
                  show={displayCarteirinha}
                  handleClose={closeCarteirinha}
                  data={studentCarteirinha}
                />
              ) : (
                <ModalAthleteModelCard
                  show={displayCarteirinha}
                  handleClose={closeCarteirinha}
                  data={studentCarteirinha}
                />
              )}

              <div className='modal' id='add_noticia_comunicado' style={display} ref={modalScroll}>
                <div className='modal_content'>
                  <div className='modal_close' onClick={closeModal}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {id === null ? t('StudentContractsPage.Modal.TitleAdd') : t('StudentContractsPage.Modal.TitleEdit')}
                  </h3>
                  <form
                    action=''
                    autoComplete='new-password'
                    onSubmit={handleSubmit}
                    method='post'
                    className='form_modal'
                  >
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='title' className='card_details_label'>
                          {t('Classes')}
                        </label>
                        <select
                          required
                          value={modalClass}
                          className='form_control select'
                          onChange={(e) => setModalClass(e.target.value)}
                        >
                          <option value='' disabled></option>
                          {modalClasss.map((item, i) => {
                            return (
                              <option
                                key={i}
                                value={item.id}
                                disabled={item.status === 'I'}
                                hidden={item.status === 'I'}
                              >
                                {item.name}
                                {item.status === 'I' ? ` (${t('InactivatedClass')})` : ''}
                              </option>
                            );
                          })}
                        </select>

                        <label
                          className='card_details_label mt-1 align-items-center flex-row'
                          style={{ color: disabled ? '#8080808c' : undefined }}
                        >
                          <input
                            className='mr-1'
                            type='checkbox'
                            disabled={disabled}
                            checked={onlyStudentClass}
                            onChange={(e) => setOnlyStudentClass(e.target.checked)}
                          />
                          {t('StudentPage.Modal.OnlyStudentInThisClass')}
                        </label>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='title' className='card_details_label'>
                          {t('Student')}
                        </label>
                        <Autocomplete
                          required
                          clearOnEscape={true}
                          disabled={disabled}
                          noOptionsText={t('NoStudentName')}
                          loadingText={t('SystemMessage.Loading.Loading')}
                          options={
                            onlyStudentClass
                              ? modalClasss.find((class_) => class_.id === +modalClass)?.students || []
                              : modalStudents
                          }
                          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                          style={{ width: '100%' }}
                          value={modalStudent}
                          onChange={(_, newValue) => {
                            setModalStudent(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} variant='outlined' required />}
                        />
                      </div>
                    </div>

                    <div className='form_group w-100'>
                      <label htmlFor='schedule' className='card_details_label'>
                        Usar um Plano Padrão
                      </label>
                      <select
                        className={`form_control select`}
                        disabled={disabled}
                        value={standDefault}
                        onChange={(e) => handlerSetDefaultPlan(e.target.value)}
                      >
                        <option value=''>Selecionar Plano Padrão</option>
                        {standPlans.map((item, i) => (
                          <option key={`stdplan${i}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label
                          htmlFor='position'
                          className='card_details_label'
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {t('ContractedService')}
                          <span
                            style={{
                              marginLeft: 10,
                              color: '#841202',
                              cursor: 'pointer',
                            }}
                            onClick={() => newServicoContratado()}
                          >
                            {t('AddDeleteServices')}
                          </span>
                        </label>
                        <select
                          required
                          value={modalService}
                          className='form_control select'
                          onChange={(e) => setModalService(e.target.value)}
                        >
                          <option value='' disabled></option>
                          {modalServices.map((item, i) => (
                            <option key={i} value={item.id}>
                              {item.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {t('BillingCycle')}
                          </label>
                          <select
                            required
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            disabled={disabled}
                            value={modalCycle}
                            onChange={(e) => setModalCycle(e.target.value)}
                          >
                            <option value='MONTHLY'>{t('Monthly')}</option>
                            <option value='QUARTERLY'>{t('Quarterly')}</option>
                            <option value='SEMIANNUALLY'>{t('Semiannually')}</option>
                            <option value='YEARLY'>{t('Yearly')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {t('PaymentMethod')}
                          </label>
                          <select
                            required
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            disabled={disabled}
                            value={modalTypePayment}
                            onChange={(e) => setModalTypePayment(e.target.value)}
                          >
                            <option value='' disabled></option>
                            <option value='CREDIT_CARD'>{t('Card')}</option>
                            <option value='BOLETO'>{t('Bill')}/PIX</option>
                            <option value='MONEY'>{t('Money')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='schedule' className='card_details_label'>
                          {t('TakeInsurance')}
                        </label>
                        <select
                          required
                          className={`form_control select ${disabled ? 'disabled' : ''}`}
                          disabled={disabled}
                          value={modalInsurance || 'NONE'}
                          onChange={(e) => setModalInsurance(e.target.value)}
                        >
                          <option value='NONE'>{t('NoInsurance')}</option>
                          <option value='PLAN_A'>{t('InsuranceTypeFormatted', { value: 1.99, coverage: 1000 })}</option>
                          {/* <option value='PLAN_B'>{t('InsuranceTypeFormatted', { value: 2.99, coverage: 2000 })}</option> */}
                          {/* <option value='PLAN_C'>{t('InsuranceTypeFormatted', { value: 3.99, coverage: 3000 })}</option> */}
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('DiscountType')}
                          </label>
                          <select
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            value={modalDiscountType || 'NONE'}
                            onChange={(e) => {
                              setModalDiscountType(e.target.value);
                              setModalDiscount(null);
                            }}
                            disabled={disabled}
                          >
                            <option value='NONE'>{t('NoDiscount')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('DiscountAmount')}</label>
                          <NumberFormat
                            required
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalDiscountType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalDiscountType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalDiscountType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalDiscountType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={disabled}
                            readOnly={modalDiscountType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalDiscountType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalDiscount * 100}
                            onValueChange={(e) => setModalDiscount(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('MonthlyAmountWithoutDiscount')}
                          </label>
                          <NumberFormat
                            required
                            className='form_control'
                            placeholder={t('PlaceholderMoneyZero')}
                            displayType={'input'}
                            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                            value={modalOriginalValue === '' ? '' : modalOriginalValue * 100}
                            onValueChange={(e) => setModalOriginalValue(e.floatValue / 100)}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('AmountInsurance')}
                          </label>
                          <NumberFormat
                            required
                            className='form_control'
                            placeholder={modalInsurance === 'NONE' ? '' : t('PlaceholderMoneyZero')}
                            displayType={'input'}
                            format={(e) => t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })}
                            value={modalInsurance === 'NONE' ? 0 : modalValue * 100}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateInterestType')}
                          </label>
                          <select
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            value={modalLateInterestType || 'NONE'}
                            onChange={(e) => {
                              setModalLateInterestType(e.target.value);
                              setModalLateInterest(null);
                            }}
                            disabled={disabled}
                          >
                            <option value='NONE'>{t('NoLateInterest')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateInterestAmount')}
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateInterestType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalLateInterestType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalLateInterestType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateInterestType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={disabled}
                            readOnly={modalLateInterestType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateInterestType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateInterest * 100}
                            onValueChange={(e) => setModalLateInterest(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateFeeType')}
                          </label>
                          <select
                            className={`form_control select ${disabled ? 'disabled' : ''}`}
                            value={modalLateFeeType || 'NONE'}
                            onChange={(e) => {
                              setModalLateFeeType(e.target.value);
                              setModalLateFee(null);
                            }}
                            disabled={disabled}
                          >
                            <option value='NONE'>{t('NoLateFee')}</option>
                            <option value='PERCENTAGE'>{t('Percentage')}</option>
                            <option value='FIXED'>{t('Fixed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('LateFeeAmount')}
                          </label>
                          <NumberFormat
                            className='form_control'
                            displayType={'input'}
                            placeholder={
                              modalLateFeeType === 'FIXED'
                                ? t('PlaceholderMoneyZero')
                                : modalLateFeeType === 'PERCENTAGE'
                                ? t('PlaceholderPercentageZero')
                                : ''
                            }
                            format={(e) =>
                              modalLateFeeType === 'FIXED'
                                ? t('FormatFunction.intlCurrencyWithOptions', { value: +e / 100 })
                                : modalLateFeeType === 'PERCENTAGE'
                                ? t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })
                                : ''
                            }
                            disabled={disabled}
                            readOnly={modalLateFeeType === 'NONE'}
                            isAllowed={({ floatValue }) =>
                              modalLateFeeType === 'PERCENTAGE' ? floatValue < 10000 : true
                            }
                            value={modalLateFee * 100}
                            onValueChange={(e) => setModalLateFee(e.floatValue / 100 || undefined)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {t('ContractStatus')}
                          </label>
                          <select
                            required
                            value={modalStatus || ''}
                            className='form_control select'
                            onChange={(e) => setModalStatus(e.target.value)}
                            disabled={disabled}
                          >
                            {/* <option value='' disabled></option> */}
                            <option value='Ativo' selected>
                              {t('StatusType.Active')}
                            </option>
                            <option value='Inativo'>{t('StatusType.Inactive')}</option>
                            <option value='Encerrado'>{t('StatusType.Closed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {modalTypePayment === 'MONEY' ? t('MonthlyWorkload') : t('Workload')}
                          </label>
                          <input
                            type='time'
                            placeholder='hh:mm'
                            className='form_control'
                            value={modalCargaHoraria}
                            onChange={(e) => setModalCargaHoraria(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='schedule' className='card_details_label'>
                          {t('FirstDueDate')}
                        </label>
                        <input
                          type='date'
                          required
                          className='form_control'
                          value={modalDateStart || ''}
                          onChange={(e) => setModalDateStart(e.target.value)}
                          disabled={modalFaturado === 'S' ? disabled : false}
                          style={{ color: modalFaturado === 'S' && disabled ? 'rgba(0, 0, 0, 0.38)' : '#3b3b3b' }}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='schedule' className='card_details_label'>
                            {t('ContractStartDate')}
                          </label>
                          <input
                            type='date'
                            required
                            className='form_control'
                            value={modalContractDateStart || ''}
                            onChange={(e) => setModalContractDateStart(e.target.value)}
                            disabled={modalFaturado === 'S' && modalTypePayment === 'MONEY' ? disabled : false}
                            style={{
                              color:
                                modalFaturado === 'S' && modalTypePayment === 'MONEY' && disabled
                                  ? 'rgba(0, 0, 0, 0.38)'
                                  : '#3b3b3b',
                            }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='descript' className='card_details_label'>
                            {t('ContractEndDate')}
                          </label>
                          <input
                            type='date'
                            className='form_control'
                            value={modalDateEnd || ''}
                            onChange={(e) => setModalDateEnd(e.target.value)}
                            required={modalTypePayment === 'MONEY' ? true : false}
                            disabled={modalFaturado === 'S' && modalTypePayment === 'MONEY' ? disabled : false}
                            style={{
                              color:
                                modalFaturado === 'S' && modalTypePayment === 'MONEY' && disabled
                                  ? 'rgba(0, 0, 0, 0.38)'
                                  : '#3b3b3b',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='descript' className='card_details_label'>
                        {t('Observations')}
                      </label>
                      <textarea
                        name='descript'
                        value={modalObservation}
                        className='form_control'
                        id=''
                        cols={30}
                        rows={5}
                        onChange={(e) => setModalObservation(e.target.value)}
                      ></textarea>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <button className='btn_card' style={{ marginRight: 5 }}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null
                          ? t('StudentContractsPage.Modal.ButtonAdd')
                          : t('StudentContractsPage.Modal.ButtonEdit')}
                      </button>
                      {id !== null && modalFaturado == 'N' && modalStatus.toLowerCase() === 'ativo' && (
                        <div className='btn_card_red' onClick={() => showMessage()}>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('InvoiceContract')}
                        </div>
                      )}
                      {modalFaturado == 'S' && (
                        <div className='btn_card_red' onClick={() => showMessageInfo()}>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('CancelInvoicing')}
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>

              <div className='modal' style={displayModalServicoContratado} id='message'>
                <div className='modal_content' style={{ minHeight: 'auto', paddingBottom: 30, height: 'auto' }}>
                  <div className='modal_close' onClick={() => closeModalServicoContratado({})}>
                    X
                  </div>
                  <h3 className='modal_title'>{t('AddContractedServices')}</h3>
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  {warning && (
                    <MessageWarningMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  <form
                    action=''
                    autoComplete='new-password'
                    method='post'
                    className='form_modal'
                    onSubmit={handleSaveServicoContratado}
                  >
                    <div className='form_group'>
                      <label htmlFor='schedule' className='card_details_label'>
                        {t('ServiceName')}
                      </label>
                      <input
                        type='text'
                        required
                        className='form_control'
                        value={modalNome}
                        onChange={(e) => setModalNome(e.target.value)}
                      />
                      <button className='btn_card' style={{ marginRight: 5 }}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('Include')}
                      </button>
                    </div>
                  </form>
                  <div style={{ margin: '10px 10px' }}>
                    <p style={{ textAlign: 'center', width: '100%', marginBottom: 20 }}>
                      <b>{modalServices.length === 0 ? t('NoRegisteredServices') : t('ListRegisteredServices')}</b>
                    </p>

                    {modalServices.map((item, i) => (
                      <>
                        <div className='row' key={i}>
                          <div className='col'>
                            {t(['RegisteredServicesModel.' + item.nome.toLowerCase(), item.nome])}
                          </div>
                          <div className='col' style={{ textAlign: 'right', color: 'brown', fontWeight: 600 }}>
                            <span onClick={() => handleDeleteServicoContratado(item.id)} style={{ cursor: 'pointer' }}>
                              {t('Delete')}
                            </span>
                          </div>
                        </div>
                        <hr style={{ borderBottom: '1px solid #f4f4f4' }} />
                      </>
                    ))}
                  </div>
                  {sendSave && (
                    <p style={{ textAlign: 'center' }}>
                      {t('SystemMessage.Loading.Processing')} <Loading type='4' style={{ color: 'green' }} />
                    </p>
                  )}
                </div>
              </div>

              <div className='modal' style={displayModalMessage} id='message'>
                <div className='modal_content' style={{ minHeight: 'auto', paddingBottom: 30, height: 'auto' }}>
                  <div
                    className='modal_close'
                    onClick={() => {
                      setDisplayModalMessage({});
                      setError(false);
                      setSuccess(false);
                      setWarning(false);
                      setWarningFaturamento(false);
                      setInfoFaturamento(false);
                      setSendSave(false);
                    }}
                  >
                    X
                  </div>
                  <h3 className='modal_title'>{t('Alert')}</h3>
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  {warning && (
                    <MessageWarningMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {warningFaturamento && (
                    <div className='alert alert-margin-top alert-warning alert-dismissible fade show' role='alert'>
                      <Trans
                        i18nKey={
                          modalTypePayment === 'MONEY'
                            ? 'StudentContractsPage.SystemMessage.WarningInvoiceContractMoney'
                            : 'StudentContractsPage.SystemMessage.WarningInvoiceContract'
                        }
                      >
                        <strong>a</strong>b
                        <br />
                        <br />c
                        <strong
                          style={{ cursor: 'pointer' }}
                          onClick={modalTypePayment === 'MONEY' ? handleFaturarDinheiro : handleFaturar}
                        >
                          d
                        </strong>
                      </Trans>
                    </div>
                  )}
                  {infoFaturamento && (
                    <div className='alert alert-margin-top alert-info alert-dismissible fade show' role='alert'>
                      <Trans
                        i18nKey={
                          modalTypePayment === 'MONEY'
                            ? 'StudentContractsPage.SystemMessage.WarningCancelInvoiceContractMoney'
                            : 'StudentContractsPage.SystemMessage.WarningCancelInvoiceContract'
                        }
                      >
                        <strong>a</strong>b
                        <br />
                        <br />c
                        <strong
                          style={{ cursor: 'pointer' }}
                          onClick={
                            modalTypePayment === 'MONEY' ? handleCancelarFaturamentoDinheiro : handleCancelarFaturamento
                          }
                        >
                          d
                        </strong>
                      </Trans>
                    </div>
                  )}
                  {sendSave && (
                    <p style={{ textAlign: 'center' }}>
                      {t('SystemMessage.Loading.Processing')} <Loading type='4' style={{ color: 'green' }} />
                    </p>
                  )}
                </div>
              </div>

              <SweetAlert
                ref={alertRef}
                show={showInfoCancelContract}
                showCancel={true}
                showConfirm={false}
                info
                title=''
                cancelBtnText='Fechar'
                cancelBtnBsStyle='info'
                onCancel={() => setShowInfoCancelContract(false)}
                onConfirm={() => setShowInfoCancelContract(false)}
              >
                <p>Contratos atualizados: {countUpdateContractSuccess}</p>
                {errorUpdateContractUsers.length > 0 && (
                  <>
                    <p>Alunos não atualizados: </p>
                    {errorUpdateContractUsers.map((student) => (
                      <p>{student}</p>
                    ))}
                  </>
                )}
              </SweetAlert>

              <SweetAlert
                ref={alertRef}
                show={showConfirmAction}
                showCancel={actionContracts && !confirmActionLoading}
                warning
                title='Atenção'
                cancelBtnText='Cancelar'
                disabled={confirmActionLoading}
                cancelBtnBsStyle='error'
                confirmBtnText={
                  confirmActionLoading
                    ? t('SystemMessage.Loading.Processing')
                    : actionContracts
                    ? t('Confirm')
                    : 'Ok, entendi!'
                }
                confirmBtnBsStyle='warning'
                onConfirm={() =>
                  actionContracts
                    ? actionContracts === 'rc'
                      ? setShowReadjust(true)
                      : confirmActionContract()
                    : setShowConfirmAction(false)
                }
                onCancel={() => {
                  if (!confirmActionLoading) {
                    setShowConfirmAction(false);
                    setActionContracts('');
                    setSelectedContracts([]);
                  }
                }}
              >
                {actionContracts ? (
                  <p>
                    Deseja realmente
                    {actionContracts == 'ct' && ' cancelar faturamento dos '}
                    {actionContracts == 'ic' && ' encerrar os '}
                    {actionContracts == 'rc' && ' reajustar os '}
                    contratos selecionados?
                  </p>
                ) : (
                  <p>Para realizar uma ação é necessário selecionar um aluno!</p>
                )}
              </SweetAlert>

              <SweetAlert
                ref={alertRef}
                show={showReadjust}
                showCancel={true}
                warning
                title='Reajustar contratos'
                cancelBtnText='Cancelar'
                disabled={confirmActionLoading}
                cancelBtnBsStyle='error'
                confirmBtnText={t('Confirm')}
                confirmBtnBsStyle='warning'
                onConfirm={confirmActionContract}
                onCancel={() => setShowReadjust(false)}
              >
                <form action='' method='post' className='form_modal' style={{ textAlign: 'left' }}>
                  <div className='form_group'>
                    <label className='card_details_label'>Tipo de Reajuste</label>
                    <select
                      value={readjustType}
                      className='form_control select'
                      onChange={(e) => {
                        setReadjustType(e.target.value);
                        setReadjustValue(0);
                      }}
                      style={{ border: '1px solid #ccc', borderRadius: '5px' }}
                    >
                      <option value='' disabled>
                        Tipo de reajuste
                      </option>
                      <option value='PERCENTAGE'>Percentual</option>
                      <option value='FIXED'>Valor Fixo</option>
                    </select>
                  </div>
                  <div className='group'>
                    {readjustType === 'PERCENTAGE' ? (
                      <div className='form_group'>
                        <label className='card_details_label'>Percentual (%)</label>
                        <NumberFormat
                          required
                          className='form_control'
                          displayType={'input'}
                          format={(e) => t('FormatFunction.intlPercentWithOptions', { value: +e / 100 })}
                          isAllowed={({ floatValue }) => floatValue < 10000}
                          value={readjustValue * 100}
                          onValueChange={(e) => setReadjustValue(e.floatValue / 100 || undefined)}
                        />
                      </div>
                    ) : (
                      <div className='form_group'>
                        <label className='card_details_label'>Valor Fixo (R$)</label>
                        <MoneyMask required value={readjustValue} onChange={setReadjustValue} />
                      </div>
                    )}
                  </div>
                </form>
              </SweetAlert>
            </main>

            <Footer />
          </div>
        </div>
      </div>
      {requestSignatureModalData.id && (
        <SignatureContractModal
          onClose={() => setRequestSignatureModalData({})}
          onSuccessRequest={() => {
            setRequestSignatureModalData({});
            requestContracts(school_id, numberDataPerPaginate).then((resp) => {
              setContractsAtivos(resp.data);
            });
          }}
          contract={requestSignatureModalData}
        />
      )}
    </main>
  );
};

//material ui custom styles --------------------------------------------------------------------------------
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'white',
    color: theme.palette.common.black,
    border: 'none',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '& td:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderLeft: `1px solid var(--main-color)`,
    },
    '& td:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      borderRight: `1px solid var(--main-color)`,
    },
    '& td': {
      borderBottom: `1px solid var(--main-color)`,
      borderTop: '1px solid var(--main-color)',
      marginBottom: theme.spacing(2),
    },
  },
}))(TableRow);

const TableSpacing = withStyles((theme) => ({
  root: {
    borderSpacing: `0 ${theme.spacing(2)}px`,
  },
}))(Table);

//private components  --------------------------------------------------------------------------------------
const BtnDelete = ({ fcolor = '#FF2929', w = 16, h = 16, onClick }) => (
  <span onClick={onClick} className='text-danger' style={{ cursor: 'pointer' }} role='button' title='teste'>
    <svg width={w} height={h} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z'
        fill={fcolor}
      />
    </svg>
  </span>
);
const BtnSign = ({ fcolor = '#3E8BFF', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.addButton')}
    >
      <svg width={w} height={h} viewBox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7 16H9V13H12V11H9V8H7V11H4V13H7V16ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};
const BtnPdf = ({ disabled = false, fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.printerButton')}
    >
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7 10.5H8V8.5H9C9.28333 8.5 9.52083 8.40417 9.7125 8.2125C9.90417 8.02083 10 7.78333 10 7.5V6.5C10 6.21667 9.90417 5.97917 9.7125 5.7875C9.52083 5.59583 9.28333 5.5 9 5.5H7V10.5ZM8 7.5V6.5H9V7.5H8ZM11 10.5H13C13.2833 10.5 13.5208 10.4042 13.7125 10.2125C13.9042 10.0208 14 9.78333 14 9.5V6.5C14 6.21667 13.9042 5.97917 13.7125 5.7875C13.5208 5.59583 13.2833 5.5 13 5.5H11V10.5ZM12 9.5V6.5H13V9.5H12ZM15 10.5H16V8.5H17V7.5H16V6.5H17V5.5H15V10.5ZM6 16C5.45 16 4.97917 15.8042 4.5875 15.4125C4.19583 15.0208 4 14.55 4 14V2C4 1.45 4.19583 0.979167 4.5875 0.5875C4.97917 0.195833 5.45 0 6 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V14C20 14.55 19.8042 15.0208 19.4125 15.4125C19.0208 15.8042 18.55 16 18 16H6ZM6 14H18V2H6V14ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4H2V18H16V20H2Z'
          fill={color}
        />
      </svg>
    </span>
  );
};
const BtnProf = ({ fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.showPass')}
    >
      <svg width={w} height={h} viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V4C0 3.45 0.195833 2.97917 0.5875 2.5875C0.979167 2.19583 1.45 2 2 2H6.2C6.43333 1.4 6.8 0.916667 7.3 0.55C7.8 0.183333 8.36667 0 9 0C9.63333 0 10.2 0.183333 10.7 0.55C11.2 0.916667 11.5667 1.4 11.8 2H16C16.55 2 17.0208 2.19583 17.4125 2.5875C17.8042 2.97917 18 3.45 18 4V18C18 18.55 17.8042 19.0208 17.4125 19.4125C17.0208 19.8042 16.55 20 16 20H2ZM9 3.25C9.21667 3.25 9.39583 3.17917 9.5375 3.0375C9.67917 2.89583 9.75 2.71667 9.75 2.5C9.75 2.28333 9.67917 2.10417 9.5375 1.9625C9.39583 1.82083 9.21667 1.75 9 1.75C8.78333 1.75 8.60417 1.82083 8.4625 1.9625C8.32083 2.10417 8.25 2.28333 8.25 2.5C8.25 2.71667 8.32083 2.89583 8.4625 3.0375C8.60417 3.17917 8.78333 3.25 9 3.25ZM2 16.85C2.9 15.9667 3.94583 15.2708 5.1375 14.7625C6.32917 14.2542 7.61667 14 9 14C10.3833 14 11.6708 14.2542 12.8625 14.7625C14.0542 15.2708 15.1 15.9667 16 16.85V4H2V16.85ZM9 12C9.96667 12 10.7917 11.6583 11.475 10.975C12.1583 10.2917 12.5 9.46667 12.5 8.5C12.5 7.53333 12.1583 6.70833 11.475 6.025C10.7917 5.34167 9.96667 5 9 5C8.03333 5 7.20833 5.34167 6.525 6.025C5.84167 6.70833 5.5 7.53333 5.5 8.5C5.5 9.46667 5.84167 10.2917 6.525 10.975C7.20833 11.6583 8.03333 12 9 12ZM4 18H14V17.75C13.3 17.1667 12.525 16.7292 11.675 16.4375C10.825 16.1458 9.93333 16 9 16C8.06667 16 7.175 16.1458 6.325 16.4375C5.475 16.7292 4.7 17.1667 4 17.75V18ZM9 10C8.58333 10 8.22917 9.85417 7.9375 9.5625C7.64583 9.27083 7.5 8.91667 7.5 8.5C7.5 8.08333 7.64583 7.72917 7.9375 7.4375C8.22917 7.14583 8.58333 7 9 7C9.41667 7 9.77083 7.14583 10.0625 7.4375C10.3542 7.72917 10.5 8.08333 10.5 8.5C10.5 8.91667 10.3542 9.27083 10.0625 9.5625C9.77083 9.85417 9.41667 10 9 10Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};
const BtnEdit = ({ fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.editButton')}
    >
      <svg width={w} height={h} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V10H14V7H9V2H2V18H8V20H2ZM14.3 12.525L15.375 13.6L11.5 17.45V18.5H12.55L16.425 14.65L17.475 15.7L13.175 20H10V16.825L14.3 12.525ZM17.475 15.7L14.3 12.525L15.75 11.075C15.9333 10.8917 16.1667 10.8 16.45 10.8C16.7333 10.8 16.9667 10.8917 17.15 11.075L18.925 12.85C19.1083 13.0333 19.2 13.2667 19.2 13.55C19.2 13.8333 19.1083 14.0667 18.925 14.25L17.475 15.7Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};
const BtnSignContract = ({ row, onClick }) => {
  const { t } = useTranslation();

  let iconColor = '#B8872B';
  let title = 'Solicitar assinaturas';
  if (row.autentique_document_status === 'SIGNED') {
    iconColor = 'green';
    title = 'Ver assinaturas';
  }

  if (['PARTIAL_SIGNED', 'NOT_SIGNED'].includes(row.autentique_document_status)) {
    iconColor = 'yellow';
    title = 'Ver assinaturas';
  }

  return (
    <button
      onClick={onClick}
      className='text-danger'
      style={{ backgroundColor: 'transparent', border: 'none' }}
      role='button'
      title={title}
    >
      <PiSignatureLight size={16} color={iconColor} />
    </button>
  );
};
const BtnEye = ({ fcolor = '#B8872B', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.showStudent')}
    >
      <svg width={w} height={h} viewBox='0 0 22 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11 12C12.25 12 13.3125 11.5625 14.1875 10.6875C15.0625 9.8125 15.5 8.75 15.5 7.5C15.5 6.25 15.0625 5.1875 14.1875 4.3125C13.3125 3.4375 12.25 3 11 3C9.75 3 8.6875 3.4375 7.8125 4.3125C6.9375 5.1875 6.5 6.25 6.5 7.5C6.5 8.75 6.9375 9.8125 7.8125 10.6875C8.6875 11.5625 9.75 12 11 12ZM11 10.2C10.25 10.2 9.6125 9.9375 9.0875 9.4125C8.5625 8.8875 8.3 8.25 8.3 7.5C8.3 6.75 8.5625 6.1125 9.0875 5.5875C9.6125 5.0625 10.25 4.8 11 4.8C11.75 4.8 12.3875 5.0625 12.9125 5.5875C13.4375 6.1125 13.7 6.75 13.7 7.5C13.7 8.25 13.4375 8.8875 12.9125 9.4125C12.3875 9.9375 11.75 10.2 11 10.2ZM11 15C8.56667 15 6.35 14.3208 4.35 12.9625C2.35 11.6042 0.9 9.78333 0 7.5C0.9 5.21667 2.35 3.39583 4.35 2.0375C6.35 0.679167 8.56667 0 11 0C13.4333 0 15.65 0.679167 17.65 2.0375C19.65 3.39583 21.1 5.21667 22 7.5C21.1 9.78333 19.65 11.6042 17.65 12.9625C15.65 14.3208 13.4333 15 11 15ZM11 13C12.8833 13 14.6125 12.5042 16.1875 11.5125C17.7625 10.5208 18.9667 9.18333 19.8 7.5C18.9667 5.81667 17.7625 4.47917 16.1875 3.4875C14.6125 2.49583 12.8833 2 11 2C9.11667 2 7.3875 2.49583 5.8125 3.4875C4.2375 4.47917 3.03333 5.81667 2.2 7.5C3.03333 9.18333 4.2375 10.5208 5.8125 11.5125C7.3875 12.5042 9.11667 13 11 13Z'
          fill={fcolor}
        />
      </svg>
    </span>
  );
};
const BtnZap = ({ disabled = false, fcolor = '#2AA45A', w = 16, h = 16, onClick }) => {
  const { t } = useTranslation();
  const color = disabled ? '#D9D9D9' : fcolor;
  return (
    <span
      onClick={onClick}
      className='text-danger'
      style={{ cursor: disabled ? 'auto' : 'pointer' }}
      role='button'
      title={t('StudentContractsPage.ActionsTableList.WhatsAppButton')}
    >
      <svg width={w} height={h} viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 19L2.65 15.2C1.38766 13.4081 0.822667 11.217 1.06104 9.03811C1.29942 6.85918 2.32479 4.84214 3.94471 3.36552C5.56463 1.8889 7.66775 1.05421 9.85938 1.0181C12.051 0.981985 14.1805 1.74693 15.8482 3.16937C17.5159 4.59182 18.6071 6.57398 18.9172 8.74387C19.2272 10.9138 18.7347 13.1222 17.5321 14.9548C16.3295 16.7873 14.4994 18.118 12.3854 18.6971C10.2713 19.2762 8.01863 19.0639 6.05 18.1L1 19Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7 8C7 8.13261 7.05268 8.25979 7.14645 8.35355C7.24021 8.44732 7.36739 8.5 7.5 8.5C7.63261 8.5 7.75979 8.44732 7.85355 8.35355C7.94732 8.25979 8 8.13261 8 8V7C8 6.86739 7.94732 6.74021 7.85355 6.64645C7.75979 6.55268 7.63261 6.5 7.5 6.5C7.36739 6.5 7.24021 6.55268 7.14645 6.64645C7.05268 6.74021 7 6.86739 7 7V8ZM7 8C7 9.32608 7.52678 10.5979 8.46447 11.5355C9.40215 12.4732 10.6739 13 12 13M12 13H13C13.1326 13 13.2598 12.9473 13.3536 12.8536C13.4473 12.7598 13.5 12.6326 13.5 12.5C13.5 12.3674 13.4473 12.2402 13.3536 12.1464C13.2598 12.0527 13.1326 12 13 12H12C11.8674 12 11.7402 12.0527 11.6464 12.1464C11.5527 12.2402 11.5 12.3674 11.5 12.5C11.5 12.6326 11.5527 12.7598 11.6464 12.8536C11.7402 12.9473 11.8674 13 12 13Z'
          stroke={color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  );
};

const StatusRender = ({ value }) => <span style={{ color: value === 'ativo' ? 'green' : 'tomato' }}>{value}</span>;

const SelectStudent = ({ value, changeSelectedContracts, checked }) => (
  <input type='checkbox' onChange={(event) => changeSelectedContracts(value, event.target.checked)} checked={checked} />
);
const SelectAllCheckbox = ({ selectAll, numSelected, rowCount, rows }) => (
  <input type='checkbox' onChange={(event) => selectAll(event, rows)} />
);

const TableList = ({ columns, rows, refer, selectAll, selecteds, mxh = 520, loading = false }) => {
  return (
    <TableContainer style={{ maxHeight: mxh, minHeight: mxh * 0.8 }}>
      <TableSpacing ref={refer} stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map((column, k) => (
              <StyledTableCell key={`h${k}-${column.id}`} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.hidden ? null : k === 0 ? (
                  <SelectAllCheckbox
                    selectAll={selectAll}
                    numSelected={selecteds.length}
                    countRows={rows.length}
                    rows={rows}
                  />
                ) : (
                  column.label
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, k) => {
            return (
              <StyledTableRow hover tabIndex={-1} key={`r${k}-${row.id}`}>
                {columns.map((column, kk) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={`cc${kk}`} align={column.align}>
                      {typeof column.format === 'function' ? column.format(value, row) : value}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </TableSpacing>
    </TableContainer>
  );
};

export default ContractsStudent;
