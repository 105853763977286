import moment from 'moment';
import React from 'react';
import TableList from './TableList';
import { useTranslation } from 'react-i18next';

const defaultColRowStyle = {
  alignItems: 'center',
  width: '200px',
  height: '100%',
  border: 'none',
  justifyContent: 'left',
};

const defaultColHeaderStyle = {
  border: 'none',
  fontSize: '14px',
  color: '#3b3b3b',
  fontWeight: 600,
  justifyContent: 'left',
};

const DebitCreditsTable = ({ transactions }) => {
  const { t } = useTranslation();
  let formatDescription = function(_, row) {
    if(row.student_contract_transaction){
      return `Contrato do aluno ${row.student_contract_transaction.contract_student.student.name} créditos`
    }

    if(!row.asaasPaymentId){
      return `Adição de ${row.totalCredits} crédito(s)`
    }

    return `Compra de ${row.totalCredits} crédito`;
  };
  const columns = [
    {
      id: 'Id',
      label: 'id',
      align: 'center',
      format: (_, row) => row.id,
    },
    {
      id: '',
      label: 'Credito',
      align: 'center',
      format: (_, row) => (!row.student_contract_transaction ? row.totalCredits : 0),
    },
    {
      id: '',
      label: 'Débito',
      align: 'center',
      format: (_, row) => (row.student_contract_transaction ? 1 : 0),
    },
    {
      id: '',
      label: t('SignatureCreditPage.Description'),
      align: 'center',
      format: formatDescription,
    },
    {
      id: 'created_at',
      label: t('SignatureCreditPage.Date'),
      align: 'center',
      format: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm'),
    },
  ];
  return <TableList columns={columns} rows={transactions} mxh={500} />;
};

export default DebitCreditsTable;
