const { location } = window;

const prodUrlApi = 'https://www.labfut.com.br/api/';
const hmgUrlApi = 'https://api.qa.fensor.com.br/api/';
const devUrlApi = 'http://hmg.labfut.com.br/api/';
const devUrlApiWindows = 'http://ps9.test/api/';
const localhostUrlApi = 'http://localhost:8000/api/';

const prodUrl = 'https://www.labfut.com.br/';
const hmgUrl = 'https://api.qa.fensor.com.br/';
const devUrl = 'http://hmg.labfut.com.br/';
const devUrlWindows = 'http://ps9.test/';
const localhostUrl = 'http://localhost:8000/';

const apiUrl = {
  PROD: prodUrlApi,
  HMG: hmgUrlApi,
  DEV: localhostUrlApi,
  DEV_WINDOWS: devUrlApiWindows,
  LOCALHOST: localhostUrlApi,
};

const resourcesUrl = {
  PROD: prodUrl,
  HMG: hmgUrl,
  DEV: localhostUrl,
  DEV_WINDOWS: devUrlWindows,
  LOCALHOST: localhostUrl,
};

const getEnvironment = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'localhost'
    ? 'LOCALHOST'
    : process.env.REACT_APP_ENVIRONMENT === 'windows'
    ? 'DEV_WINDOWS'
    : location.hostname === 'localhost' || location.hostname === '127.0.0.1' || location.hostname === ''
    ? 'DEV'
    : location.hostname === 'azure.qa.fensor.com.br'
    ? 'HMG'
    : 'PROD';
};

const getAccountFensorAsaas = () => {
  let settings = {
    cnpj: '32397957000100',
    password: 'assas2020@v1#',
  }

  if (getEnvironment() === 'PROD') {
    return {
      ...settings,
      loginEmail: 'fensorbrprod@gmail.com',
      user_id: 3,
      system_id: 1,
      wallet: '0f29a0b1-34fe-4e65-9e90-f15eeb79e280',
      token_assas: '$aact_YTU5YTE0M2M2N2I4MTliNzk0YTI5N2U5MzdjNWZmNDQ6OjAwMDAwMDAwMDAwMDAwNDAyODc6OiRhYWNoXzI2MTVlZTkxLTIyNDMtNDQyMy1hZDIzLTRmODNmNzJiZTBjNg=='
    }
  }

  return {
    ...settings,
    loginEmail: 'adriano@fensor.com.br',
    user_id: 334,
    system_id: 3,
    wallet: '92c13cd2-cc78-4d2d-85bf-8834d85258da',
    token_assas: '$aact_YTU5YTE0M2M2N2I4MTliNzk0YTI5N2U5MzdjNWZmNDQ6OjAwMDAwMDAwMDAwMDAwODM2NzM6OiRhYWNoXzc3YzAzNmU1LTE1Y2YtNGFlNi1iZDA0LTcwYzFjNDViYzI0ZA=='
  }
}

const baseURL = apiUrl[getEnvironment()];
const resourceUrl = resourcesUrl[getEnvironment()];

const api = () => {
  return baseURL;
};

const uploadFolder = () => {
  return resourceUrl;
};

export default api;
export { baseURL, getAccountFensorAsaas, getEnvironment, resourceUrl, uploadFolder };